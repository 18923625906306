import { useServicePartnerStore } from "@/store/partner.store";
import { hasPermission } from "@/composables/customer";
import { useUserStore } from "@/store/user.store";
import { type RouteRecordRaw } from "vue-router";
import { logOut } from "@/composables/user";
import { useProducerStore } from "@/store/producer.store";
import { isBusinessInstallmentsApiFeatureEnabled } from "@/composables/featureToggles";

export const routes: RouteRecordRaw[] = [
    {
        path: "/zakelijk/login",
        name: "navigatie.zakelijk.inloggen",
        component: () => import("@/views/GzLogin.vue"),
        meta: {
            visible: () => false,
            noAuth: true,
            funnelPage: {
                logoLink: "https://www.greenchoice.nl/zakelijk",
                cancelFallbackLink: "https://www.greenchoice.nl/zakelijk",
            },
        },
        children: [
            {
                path: "forget",
                name: "navigatie.zakelijk.wachtwoordVergeten",
                component: () => import("@/views/GzLoginForgetPassword.vue"),
                meta: {
                    visible: () => false,
                    skipAutoLogon: true,
                    noAuth: true,
                },
            },
            {
                path: "change/:data",
                name: "navigatie.zakelijk.wachtwoordVerander",
                component: () => import("@/views/GzLoginResetPassword.vue"),
                meta: {
                    visible: () => false,
                    skipAutoLogon: true,
                    hideSubnav: true,
                    noAuth: true,
                },
            },
        ],
    },
    {
        path: "/zakelijk",
        name: "navigatie.zakelijk.dashboard",
        component: () => import("@/views/GzDashboard.vue"),
        meta: {
            navigation: "main",
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "md",
                },
                footer: {
                    show: true,
                },
            },
            visible: () => false,
        },
    },
    {
        path: "/zakelijk/klantenoverzicht",
        name: "navigatie.zakelijk.klantenoverzicht",
        meta: {
            navigation: "main",
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "md",
                },
                footer: {
                    show: true,
                },
            },
            permission: () =>
                useUserStore().isBusiness &&
                useUserStore().isAuthenticatedBusiness &&
                useServicePartnerStore().isPartner,
            visible: () =>
                useUserStore().isBusiness &&
                useUserStore().isAuthenticatedBusiness &&
                useServicePartnerStore().isPartner,
        },
        component: () => import("@/views/GzDashboardPartners.vue"),
    },
    {
        path: "/zakelijk/aansluitingen",
        name: "navigatie.zakelijk.aansluitingen",
        component: () => import("@/views/GzConnections.vue"),
        meta: {
            description: "connections.description",
            navigation: "main",
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "md",
                },
                footer: {
                    show: true,
                },
            },
            visible: () => {
                const servicePartnerStatus = useServicePartnerStore();
                const hasActiveCustomerSession =
                    servicePartnerStatus.isPartner &&
                    servicePartnerStatus.selectedClient !== null;

                return (
                    useUserStore().isBusiness &&
                    useUserStore().isAuthenticatedBusiness &&
                    hasPermission("aansluiting", "raadpleeg") &&
                    (!servicePartnerStatus.isPartner ||
                        hasActiveCustomerSession)
                );
            },
            permission: () =>
                useUserStore().isBusiness &&
                useUserStore().isAuthenticatedBusiness,
        },
        children: [
            {
                path: ":ean",
                name: "navigatie.zakelijk.aansluiting",
                component: () => import("@/views/GzConnection.vue"),
                meta: {
                    page: {
                        hero: {
                            prefix: "Mijn",
                            pull: "md",
                        },
                        footer: {
                            show: true,
                        },
                    },
                    visible: () =>
                        useUserStore().isBusiness &&
                        useUserStore().isAuthenticatedBusiness,
                },
            },
        ],
    },
    {
        path: "/zakelijk/contracten",
        name: "navigatie.zakelijk.contracten",
        component: () => import("@/views/GzContracten.vue"),
        meta: {
            description: "contracts.description",
            navigation: "main",
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "md",
                },
                footer: {
                    show: true,
                },
            },
            permission: () =>
                useUserStore().isBusiness &&
                useUserStore().isAuthenticatedBusiness &&
                hasPermission("contract", "raadpleeg"),
            visible: () => {
                const servicePartnerStatus = useServicePartnerStore();
                const hasActiveCustomerSession =
                    servicePartnerStatus.isPartner &&
                    servicePartnerStatus.selectedClient !== null;
                return (
                    useUserStore().isBusiness &&
                    useUserStore().isAuthenticatedBusiness &&
                    hasPermission("contract", "raadpleeg") &&
                    (!servicePartnerStatus.isPartner ||
                        hasActiveCustomerSession)
                );
            },
        },
        children: [
            {
                path: ":id",
                name: "navigatie.zakelijk.contract",
                component: () => import("@/views/GzContract.vue"),
                meta: {
                    visible: () =>
                        useUserStore().isBusiness &&
                        useUserStore().isAuthenticatedBusiness,
                    permission: () =>
                        useUserStore().isBusiness &&
                        useUserStore().isAuthenticatedBusiness &&
                        hasPermission("contract", "raadpleeg"),
                    page: {
                        hero: {
                            prefix: "Mijn",
                            pull: "md",
                        },
                        footer: {
                            show: true,
                        },
                    },
                },
            },
            {
                path: ":id/:name",
                name: "navigatie.zakelijk.contract.obsolete",
                redirect: (to) => {
                    // the function receives the target route as the argument
                    // we return a redirect path/location here.
                    return {
                        path: "/zakelijk/contracten/" + to.params.id,
                    };
                },
                meta: {
                    visible: () => false,
                },
            },
        ],
    },
    {
        path: "/zakelijk/termijnbedragen",
        name: "navigatie.zakelijk.termijnbedragen",
        component: () => {
            if (isBusinessInstallmentsApiFeatureEnabled()) {
                return import("@/views/GzInstallmentAmounts.vue");
            } else {
                return import("@/views/GzTermijnbedragen.vue");
            }
        },
        meta: {
            description: "finance.installmentamountrange",
            navigation: "main",
            visible: () => {
                const servicePartnerStatus = useServicePartnerStore();
                const hasActiveCustomerSession =
                    servicePartnerStatus.isPartner &&
                    servicePartnerStatus.selectedClient !== null;
                return (
                    useUserStore().isBusiness &&
                    useUserStore().isAuthenticatedBusiness &&
                    hasPermission("termijnbedrag", "raadpleeg") &&
                    (!servicePartnerStatus.isPartner ||
                        hasActiveCustomerSession)
                );
            },
            permission: () =>
                useUserStore().isBusiness &&
                useUserStore().isAuthenticatedBusiness &&
                hasPermission("termijnbedrag", "raadpleeg"),
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "md",
                },
                footer: {
                    show: true,
                },
            },
        },
        props: (route) => ({
            segment: route.query.segment || null,
        }),
    },
    {
        path: "/zakelijk/contactpersonen",
        name: "navigatie.zakelijk.contactpersonen",
        component: () => import("@/views/GzContactpersonen.vue"),
        meta: {
            description: "contacts.description",
            navigation: "profile",
            visible: () => {
                const servicePartnerStatus = useServicePartnerStore();
                const hasActiveCustomerSession =
                    servicePartnerStatus.isPartner &&
                    servicePartnerStatus.selectedClient !== null;
                return (
                    useUserStore().isBusiness &&
                    useUserStore().isAuthenticatedBusiness &&
                    hasPermission("contactpersoon", "raadpleeg") &&
                    (!servicePartnerStatus.isPartner ||
                        hasActiveCustomerSession)
                );
            },
            permission: () =>
                useUserStore().isBusiness &&
                useUserStore().isAuthenticatedBusiness &&
                hasPermission("contactpersoon", "raadpleeg"),
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "lg",
                },
                footer: {
                    show: true,
                },
            },
        },
        children: [
            {
                path: "contactpersoon/:id?",
                name: "navigatie.zakelijk.contactpersoon",
                component: () => import("@/views/GzContactpersoon.vue"),
                meta: {
                    visible: () => false,
                    permission: () =>
                        useUserStore().isBusiness &&
                        useUserStore().isAuthenticatedBusiness &&
                        hasPermission("contactpersoon", "raadpleeg"),
                    page: {
                        hero: {
                            prefix: "Bewerken",
                            pull: "md",
                        },
                        footer: {
                            show: true,
                        },
                    },
                },
            },
        ],
    },
    {
        path: "/zakelijk/facturen",
        name: "navigatie.zakelijk.facturen",
        component: () => import("@/views/GzInvoices.vue"),
        meta: {
            description: "finance.description",
            navigation: "main",
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "md",
                },
                footer: {
                    show: true,
                },
            },
            visible: () => {
                const servicePartnerStatus = useServicePartnerStore();
                const hasActiveCustomerSession =
                    servicePartnerStatus.isPartner &&
                    servicePartnerStatus.selectedClient !== null;
                return (
                    useUserStore().isBusiness &&
                    useUserStore().isAuthenticatedBusiness &&
                    hasPermission("factuur", "raadpleeg") &&
                    (!servicePartnerStatus.isPartner ||
                        hasActiveCustomerSession)
                );
            },
            permission: () =>
                useUserStore().isBusiness &&
                useUserStore().isAuthenticatedBusiness &&
                hasPermission("factuur", "raadpleeg"),
        },
        children: [
            {
                path: ":id",
                name: "navigatie.zakelijk.factuur",
                component: () => import("@/views/GzInvoice.vue"),
                meta: {
                    visible: () => false,
                    permission: () =>
                        useUserStore().isBusiness &&
                        useUserStore().isAuthenticatedBusiness &&
                        hasPermission("factuur", "raadpleeg"),
                    page: {
                        hero: {
                            prefix: "Mijn",
                            pull: "md",
                        },
                        footer: {
                            show: true,
                        },
                    },
                    template: "tailwind",
                },
            },
        ],
    },
    {
        path: "/zakelijk/mutaties",
        name: "navigatie.zakelijk.mutaties",
        component: () => import("@/views/GzMutaties.vue"),
        meta: {
            description: "mutatie.description",
            navigation: "main",
            visible: () => {
                const servicePartnerStatus = useServicePartnerStore();
                const hasActiveCustomerSession =
                    servicePartnerStatus.isPartner &&
                    servicePartnerStatus.selectedClient !== null;
                return (
                    useUserStore().isBusiness &&
                    useUserStore().isAuthenticatedBusiness &&
                    hasPermission("mutatie", "raadpleeg") &&
                    (!servicePartnerStatus.isPartner ||
                        hasActiveCustomerSession)
                );
            },
            permission: () =>
                useUserStore().isBusiness &&
                useUserStore().isAuthenticatedBusiness &&
                hasPermission("mutatie", "raadpleeg"),
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "md",
                },
                footer: {
                    show: true,
                },
            },
        },
        children: [
            {
                path: "aanmelden/:stap?",
                name: "navigatie.zakelijk.aanmelden",
                component: () => import("@/views/GzMutatieAanmelding.vue"),
                meta: {
                    visible: () => {
                        const servicePartnerStatus = useServicePartnerStore();
                        const hasActiveCustomerSession =
                            servicePartnerStatus.isPartner &&
                            servicePartnerStatus.selectedClient !== null;
                        return (
                            useUserStore().isBusiness &&
                            useUserStore().isAuthenticatedBusiness &&
                            hasPermission("mutatie", "wijzig") &&
                            (!servicePartnerStatus.isPartner ||
                                hasActiveCustomerSession)
                        );
                    },
                    permission: () =>
                        useUserStore().isBusiness &&
                        useUserStore().isAuthenticatedBusiness &&
                        hasPermission("mutatie", "wijzig"),
                    description: "mutatie.housingdescription",
                    navigation: "dropdown",
                    page: {
                        hero: {
                            prefix: "Aansluitingen",
                            pull: "md",
                        },
                        footer: {
                            show: true,
                        },
                    },
                },
            },
            {
                path: "afmelden",
                name: "navigatie.zakelijk.afmelden",
                component: () => import("@/views/GzMutatieAfmelding.vue"),
                meta: {
                    visible: () => {
                        const servicePartnerStatus = useServicePartnerStore();
                        const hasActiveCustomerSession =
                            servicePartnerStatus.isPartner &&
                            servicePartnerStatus.selectedClient !== null;
                        return (
                            useUserStore().isBusiness &&
                            useUserStore().isAuthenticatedBusiness &&
                            hasPermission("mutatie", "wijzig") &&
                            (!servicePartnerStatus.isPartner ||
                                hasActiveCustomerSession)
                        );
                    },
                    permission: () =>
                        useUserStore().isBusiness &&
                        useUserStore().isAuthenticatedBusiness &&
                        hasPermission("mutatie", "wijzig"),
                    description: "mutatie.relocationdescription",
                    navigation: "dropdown",
                    page: {
                        hero: {
                            prefix: "Mijn",
                            pull: "md",
                        },
                        footer: {
                            show: true,
                        },
                    },
                },
            },
        ],
    },
    {
        path: "/zakelijk/verbruik",
        name: "navigatie.zakelijk.verbruik",
        component: () => import("@/views/GzVerbruik.vue"),
        meta: {
            visible: () => {
                const servicePartnerStatus = useServicePartnerStore();
                const hasActiveCustomerSession =
                    servicePartnerStatus.isPartner &&
                    servicePartnerStatus.selectedClient !== null;
                return (
                    useUserStore().isBusiness &&
                    useUserStore().isAuthenticatedBusiness &&
                    hasPermission("verbruik", "raadpleeg") &&
                    (!servicePartnerStatus.isPartner ||
                        hasActiveCustomerSession)
                );
            },
            permission: () =>
                useUserStore().isBusiness &&
                useUserStore().isAuthenticatedBusiness &&
                hasPermission("verbruik", "raadpleeg"),
            description: "usage.description",
            navigation: "main",
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "md",
                },
                footer: {
                    show: true,
                },
            },
        },
        props: (route) => ({
            ean: route.query.ean || null,
        }),
    },
    {
        path: "/zakelijk/meterstanden",
        name: "navigatie.zakelijk.meterstanden",
        component: () => import("@/views/GzMeterstanden.vue"),
        meta: {
            visible: () => {
                const servicePartnerStatus = useServicePartnerStore();
                const hasActiveCustomerSession =
                    servicePartnerStatus.isPartner &&
                    servicePartnerStatus.selectedClient !== null;
                return (
                    useUserStore().isBusiness &&
                    useUserStore().isAuthenticatedBusiness &&
                    hasPermission("meterstanden", "raadpleeg") &&
                    (!servicePartnerStatus.isPartner ||
                        hasActiveCustomerSession)
                );
            },
            permission: () =>
                useUserStore().isBusiness &&
                useUserStore().isAuthenticatedBusiness &&
                hasPermission("meterstanden", "raadpleeg"),
            description: "meterreadings.description",
            navigation: "main",
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "md",
                },
                footer: {
                    show: true,
                },
            },
        },
        children: [
            {
                path: "doorgeven",
                name: "navigatie.zakelijk.meterstandenDoorgeven",
                component: () => import("@/views/GzMeterstandenDoorgeven.vue"),
                meta: {
                    visible: () => {
                        const servicePartnerStatus = useServicePartnerStore();
                        const hasActiveCustomerSession =
                            servicePartnerStatus.isPartner &&
                            servicePartnerStatus.selectedClient !== null;
                        return (
                            useUserStore().isBusiness &&
                            useUserStore().isAuthenticatedBusiness &&
                            hasPermission("meterstanden", "wijzig") &&
                            (!servicePartnerStatus.isPartner ||
                                hasActiveCustomerSession)
                        );
                    },
                    permission: () =>
                        useUserStore().isBusiness &&
                        useUserStore().isAuthenticatedBusiness &&
                        hasPermission("meterstanden", "wijzig"),
                    page: {
                        hero: {
                            prefix: "Mijn",
                            pull: "lg",
                        },
                        footer: {
                            show: true,
                        },
                    },
                },
            },
        ],
        props: (route) => ({
            ean: route.query.ean || null,
        }),
    },
    {
        path: "/zakelijk/contact",
        name: "navigatie.zakelijk.contact",
        component: () => import("@/views/GzContact.vue"),
        meta: {
            navigation: "main",
            page: {
                hero: {
                    prefix: "Zoek",
                    pull: "lg",
                },
                footer: {
                    show: true,
                },
            },
            permission: () =>
                useUserStore().isBusiness &&
                useUserStore().isAuthenticatedBusiness,
            visible: () =>
                useUserStore().isBusiness &&
                useUserStore().isAuthenticatedBusiness,
        },
    },
    {
        path: "/zakelijk/opzegvergoeding",
        name: "navigatie.zakelijk.opzegvergoeding",
        component: () => import("@/views/GzTerminationCompensation.vue"),
        meta: {
            visible: () => false,
            funnelPage: {
                logoLink: "/zakelijk",
                cancelFallbackLink: "/zakelijk",
            },
        },
    },
    {
        path: "/zakelijk/logout",
        name: "navigatie.zakelijk.uitloggen",
        component: {
            template: "<div></div>",
            // Empty component, will not be used, but required for the route to work
            // The logOut function will handle the logout and navigation via router.push
        },
        beforeEnter: async (to, from, next) => {
            const result = await logOut(from);
            if (result) {
                next(); // If the logOut was successful, proceed
            } else {
                next({ name: "navigatie.zakelijk.inloggen" }); // if logOut was unsuccessful, redirect to default login.
            }
        },
        meta: {
            navigation: "profile",
            visible: () => useUserStore().isBusiness,
        },
    },
    {
        // landingpage for producers is considered to be /aansluitingen so everything landing on this url should be redirected
        path: "/producenten",
        name: "navigatie.producenten.homepage",
        redirect: {
            name: "navigatie.producenten.aansluitingen",
        },
        meta: {
            visible: () => false,
        },
    },
    {
        path: "/producenten/login",
        name: "navigatie.producenten.inloggen",
        component: () => import("@/views/GzLogin.vue"),
        meta: {
            visible: () => false,
            noAuth: true,
            funnelPage: {
                logoLink: "https://www.greenchoice.nl/zakelijk",
                cancelFallbackLink: "https://www.greenchoice.nl/zakelijk",
            },
        },
        children: [
            {
                path: "forget",
                name: "navigatie.producenten.wachtwoordVergeten",
                component: () => import("@/views/GzLoginForgetPassword.vue"),
                meta: {
                    visible: () => false,
                    skipAutoLogon: true,
                    noAuth: true,
                },
            },
            {
                path: "change/:data",
                name: "navigatie.producenten.wachtwoordVerander",
                component: () => import("@/views/GzLoginResetPassword.vue"),
                meta: {
                    visible: () => false,
                    skipAutoLogon: true,
                    hideSubnav: true,
                    noAuth: true,
                },
            },
        ],
    },
    {
        path: "/producenten/profiel",
        name: "navigatie.producenten.profile",
        component: () => import("@/views/GzProfile.vue"),
        meta: {
            description: "profileProducenten.description",
            navigation: "profile",
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "sm",
                },
            },
            permission: () =>
                useUserStore().isProducer &&
                useUserStore().isAuthenticatedProducer,
            visible: () =>
                useUserStore().isProducer &&
                useUserStore().isAuthenticatedProducer,
        },
    },
    {
        path: "/producenten/bedrijf-keuze",
        name: "navigatie.producenten.customerSelection",
        meta: {
            navigation: "profile",
            page: {
                navigation: {
                    show: true,
                    menu: false,
                },
                hero: {
                    show: false,
                },
            },
            permission: () =>
                useUserStore().isProducer &&
                useUserStore().isAuthenticatedProducer,
            visible: () =>
                useUserStore().isProducer &&
                useUserStore().isAuthenticatedProducer &&
                useProducerStore().numberOfCustomers !== null &&
                useProducerStore().numberOfCustomers! > 1,
        },
        component: () => import("@/views/GzCustomerSelection.vue"),
    },
    {
        path: "/producenten/aansluitingen",
        name: "navigatie.producenten.aansluitingen",
        component: () => import("@/views/GzConnectionsProducers.vue"),
        meta: {
            producerCustomerNeeded: true,
            description: "aansluitingenProducenten.description",
            navigation: "main",
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "md",
                },
            },
            permission: () =>
                useUserStore().isProducer &&
                useUserStore().isAuthenticatedProducer,
            visible: () =>
                useUserStore().isProducer &&
                useUserStore().isAuthenticatedProducer,
        },
    },
    {
        path: "/producenten/notas",
        name: "navigatie.producenten.notas",
        component: () => import("@/views/GzInvoicesProducers.vue"),
        meta: {
            description: "notasProducenten.description",
            navigation: "main",
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "md",
                },
            },
            permission: () =>
                useUserStore().isProducer &&
                useUserStore().isAuthenticatedProducer,
            visible: () =>
                useUserStore().isProducer &&
                useUserStore().isAuthenticatedProducer,
        },
    },
    {
        path: "/producenten/verbruik",
        name: "navigatie.producenten.verbruik",
        component: () => import("@/views/GzWIP.vue"),
        meta: {
            description: "verbruikProducenten.description",
            navigation: "main",
            page: {
                hero: {
                    prefix: "Mijn",
                    pull: "md",
                },
            },
            permission: () =>
                useUserStore().isProducer &&
                useUserStore().isAuthenticatedProducer &&
                false,
            visible: () =>
                useUserStore().isProducer &&
                useUserStore().isAuthenticatedProducer &&
                false,
        },
    },
    {
        path: "/producenten/contact",
        name: "navigatie.producenten.contact",
        component: () => import("@/views/GzContact.vue"),
        meta: {
            navigation: "main",
            page: {
                hero: {
                    prefix: "Zoek",
                    pull: "lg",
                },
            },
            permission: () =>
                useUserStore().isProducer &&
                useUserStore().isAuthenticatedProducer,
            visible: () =>
                useUserStore().isProducer &&
                useUserStore().isAuthenticatedProducer,
        },
    },
    {
        path: "/producenten/logout",
        name: "navigatie.producenten.uitloggen",
        component: {
            template: "<div></div>",
            // Empty component, will not be used, but required for the route to work
            // The logOut function will handle the logout and navigation via router.push
        },
        beforeEnter: async (to, from, next) => {
            const result = await logOut(from);
            if (result) {
                next(); // If the logOut was successful, proceed
            } else {
                next({ name: "navigatie.producenten.inloggen" }); // if logOut was unsuccessful, redirect to producenten login.
            }
        },
        meta: {
            navigation: "profile",
            visible: () => useUserStore().isProducer,
        },
    },
    // For backward compatibility reasons the next section contains deprecated urls that need to be forwarded to the newly implemented ones
    {
        path: "/login",
        name: "navigatie.inloggen",
        redirect: {
            name: "navigatie.zakelijk.inloggen",
        },
        meta: {
            visible: () => false,
        },
        children: [
            {
                path: "forget",
                name: "navigatie.wachtwoordVergeten",
                redirect: {
                    name: "navigatie.zakelijk.wachtwoordVergeten",
                },
                meta: {
                    visible: () => false,
                },
            },
            {
                path: "change/:data",
                name: "navigatie.wachtwoordVerander",
                redirect: (to) => {
                    // the function receives the target route as the argument
                    // we return a redirect path/location here.
                    return { path: "/zakelijk/login/change/" + to.params.data };
                },
                meta: {
                    visible: () => false,
                },
            },
        ],
    },
    {
        path: "/logout",
        name: "navigatie.uitloggen",
        redirect: {
            name: "navigatie.zakelijk.uitloggen",
        },
        meta: {
            visible: () => false,
        },
    },
    {
        path: "/",
        name: "navigatie.dashboard",
        redirect: () => {
            if (useUserStore().isProducer) {
                return { name: "navigatie.producenten.homepage" };
            } else {
                return { name: "navigatie.zakelijk.dashboard" };
            }
        },
        meta: {
            visible: () => false,
        },
    },
    {
        path: "/klantenoverzicht",
        name: "navigatie.klantenoverzicht",
        redirect: {
            name: "navigatie.zakelijk.klantenoverzicht",
        },
        meta: {
            visible: () => false,
        },
    },
    {
        path: "/aansluitingen",
        name: "navigatie.aansluitingen",
        component: {
            template: "<div></div>",
            // Empty component, will not be used, but required for the route to work
        },
        redirect: {
            name: "navigatie.zakelijk.aansluitingen",
        },
        meta: {
            visible: () => false,
        },
        children: [
            {
                path: ":ean",
                name: "navigatie.aansluiting",
                redirect: (to) => {
                    // the function receives the target route as the argument
                    // we return a redirect path/location here.
                    return { path: "/zakelijk/aansluitingen/" + to.params.ean };
                },
                meta: {
                    visible: () => false,
                },
            },
        ],
    },
    {
        path: "/contracten",
        name: "navigatie.contracten",
        redirect: {
            name: "navigatie.zakelijk.contracten",
        },
        meta: {
            visible: () => false,
        },
        children: [
            {
                path: ":id/:name",
                name: "navigatie.contract",
                redirect: (to) => {
                    // the function receives the target route as the argument
                    // we return a redirect path/location here.
                    return {
                        path:
                            "/zakelijk/contracten/" +
                            to.params.id +
                            "/" +
                            to.params.name,
                    };
                },
                meta: {
                    visible: () => false,
                },
            },
        ],
    },
    {
        path: "/termijnbedragen",
        name: "navigatie.termijnbedragen",
        redirect: {
            name: "navigatie.zakelijk.termijnbedragen",
        },
        meta: {
            visible: () => false,
        },
    },
    {
        path: "/contactpersonen",
        name: "navigatie.contactpersonen",
        redirect: {
            name: "navigatie.zakelijk.contactpersonen",
        },
        meta: {
            visible: () => false,
        },
        children: [
            {
                path: "contactpersoon/:id?",
                name: "navigatie.contactpersoon",
                redirect: (to) => {
                    // the function receives the target route as the argument
                    // we return a redirect path/location here.
                    return {
                        path:
                            "/zakelijk/contactpersonen/contactpersoon/" +
                            to.params.id,
                    };
                },
                meta: {
                    visible: () => false,
                },
            },
        ],
    },
    {
        path: "/facturen",
        name: "navigatie.facturen",
        redirect: {
            name: "navigatie.zakelijk.facturen",
        },
        meta: {
            visible: () => false,
        },
        children: [
            {
                path: ":id",
                name: "navigatie.factuur",
                redirect: (to) => {
                    // the function receives the target route as the argument
                    // we return a redirect path/location here.
                    return { path: "/zakelijk/facturen/" + to.params.id };
                },
                meta: {
                    visible: () => false,
                },
            },
        ],
    },
    {
        path: "/mutaties",
        name: "navigatie.mutaties",
        redirect: {
            name: "navigatie.zakelijk.mutaties",
        },
        meta: {
            visible: () => false,
        },
        children: [
            {
                path: "aanmelden/:stap?",
                name: "navigatie.aanmelden",
                redirect: {
                    name: "navigatie.zakelijk.aanmelden",
                },
                meta: {
                    visible: () => false,
                },
            },
            {
                path: "afmelden",
                name: "navigatie.afmelden",
                redirect: {
                    name: "navigatie.zakelijk.afmelden",
                },
                meta: {
                    visible: () => false,
                },
            },
        ],
    },
    {
        path: "/verbruik",
        name: "navigatie.verbruik",
        redirect: {
            name: "navigatie.zakelijk.verbruik",
        },
        meta: {
            visible: () => false,
        },
    },
    {
        path: "/meterstanden",
        name: "navigatie.meterstanden",
        redirect: {
            name: "navigatie.zakelijk.meterstanden",
        },
        meta: {
            visible: () => false,
        },
        children: [
            {
                path: "doorgeven",
                name: "navigatie.meterstandenDoorgeven",
                redirect: {
                    name: "navigatie.zakelijk.meterstandenDoorgeven",
                },
                meta: {
                    visible: () => false,
                },
            },
        ],
    },
    {
        path: "/contact",
        name: "navigatie.contact",
        redirect: {
            name: "navigatie.zakelijk.contact",
        },
        meta: {
            visible: () => false,
        },
    },
    {
        path: "/opzegvergoeding",
        name: "navigatie.opzegvergoeding",
        redirect: {
            name: "navigatie.zakelijk.opzegvergoeding",
        },
        meta: {
            visible: () => false,
        },
    },
];
