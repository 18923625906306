<template>
    <div
        class="bg-[var(--gds-color-bg-brand-2)] text-[var(--gds-color-text-on-color)]"
    >
        <GdsGrid
            variant="main"
            is-centered
            class="gap-y-[var(--gds-space-xxl)] pb-[var(--gds-space-s)] pt-[var(--gds-space-xl)] sm:gap-y-[var(--gds-space-l)]"
        >
            <GdsGridItem
                column-span="75"
                class="grid gap-y-[var(--gds-space-s)]"
            >
                <h3 class="[font-size:var(--gds-font-size-heading-3)]">
                    {{ t("footer.title") }}
                </h3>
                <div class="[font-size:var(--gds-font-size-body-l)]">
                    {{ t("footer.description") }}
                </div>
            </GdsGridItem>
            <GdsGridItem column-span="25" :row-span="2">
                <div
                    v-if="selectedHighlight !== null"
                    class="grid gap-[var(--gds-space-xl)]"
                >
                    <a :href="selectedHighlight?.href">
                        <div class="flex-inline relative rounded pt-3">
                            <h1
                                class="absolute -left-1 -top-1 inline-block -rotate-[4deg] bg-[var(--gds-color-bg-lightest)] px-1 font-bebas-neue font-bold text-[var(--gds-color-text-headline)] [font-size:var(--gds-font-size-heading-5)]"
                                data-testid="titlePrefix"
                            >
                                {{ t("footer.highlighted") }}
                            </h1>
                            <img
                                :src="selectedHighlight?.imageSrc"
                                :alt="selectedHighlight?.imageAlt"
                                class="h-[155px] w-full object-cover object-center rounded-t"
                            />
                            <div
                                class="rounded-b bg-[var(--gds-color-bg-light)] p-[var(--gds-space-xs)] text-center text-[var(--gds-color-text-default)] [font-size:var(--gds-font-size-heading-6)]"
                            >
                                <h6>{{ selectedHighlight?.title }}</h6>
                            </div>
                        </div>
                    </a>
                    <GdsButton
                        variant="primary"
                        has-trailing-arrow
                        href="https://www.greenchoice.nl/natuur-voor-morgen"
                    >
                        {{ t("footer.moreInfo") }}
                    </GdsButton>
                </div>
            </GdsGridItem>
            <GdsGridItem column-span="75">
                <GdsGrid variant="main" class="gap-y-[var(--gds-space-xxl)]">
                    <GdsGridItem column-span="33">
                        <ul
                            class="space-y-[var(--gds-space-xs)] [font-size:var(--gds-font-size-body-s)]"
                        >
                            <li>
                                <h6
                                    class="[font-size:var(--gds-font-size-heading-6)]"
                                >
                                    {{ t("footer.customerService") }}
                                </h6>
                            </li>
                            <li>
                                <GdsLink
                                    variant="on-color"
                                    style="text-decoration: none"
                                    href="https://www.greenchoice.nl/mkb/klantenservice/termijnbedrag/"
                                >
                                    {{ t("footer.installmentAmount") }}
                                </GdsLink>
                            </li>
                            <li>
                                <GdsLink
                                    variant="on-color"
                                    style="text-decoration: none"
                                    href="https://www.greenchoice.nl/mkb/klantenservice/meterstanden/"
                                >
                                    {{ t("footer.meterReadings") }}
                                </GdsLink>
                            </li>
                            <li>
                                <GdsLink
                                    variant="on-color"
                                    style="text-decoration: none"
                                    href="https://www.greenchoice.nl/mkb/klantenservice/opzegvergoeding/"
                                >
                                    {{ t("footer.terminationFee") }}
                                </GdsLink>
                            </li>
                            <li>
                                <GdsLink
                                    variant="on-color"
                                    style="text-decoration: none"
                                    href="https://www.greenchoice.nl/mkb/klantenservice/"
                                >
                                    {{ t("footer.otherQuestions") }}
                                </GdsLink>
                            </li>
                        </ul>
                    </GdsGridItem>
                    <GdsGridItem column-span="33">
                        <ul
                            class="space-y-[var(--gds-space-xs)] [font-size:var(--gds-font-size-body-s)]"
                        >
                            <li>
                                <h6
                                    class="[font-size:var(--gds-font-size-heading-6)]"
                                >
                                    {{ t("footer.company") }}
                                </h6>
                            </li>
                            <li>
                                <GdsLink
                                    variant="on-color"
                                    style="text-decoration: none"
                                    href="https://www.greenchoice.nl/nieuws/artikelen/"
                                >
                                    {{ t("footer.news") }}
                                </GdsLink>
                            </li>
                            <li>
                                <GdsLink
                                    variant="on-color"
                                    style="text-decoration: none"
                                    href="https://werkenbij.greenchoice.nl/"
                                >
                                    {{ t("footer.workAtGreenchoice") }}
                                </GdsLink>
                            </li>
                            <li>
                                <GdsLink
                                    variant="on-color"
                                    style="text-decoration: none"
                                    href="https://www.greenchoice.nl/privacy/"
                                >
                                    {{ t("footer.privacy") }}
                                </GdsLink>
                            </li>
                            <li>
                                <GdsLink
                                    variant="on-color"
                                    style="text-decoration: none"
                                    href="https://www.greenchoice.nl/cookies/"
                                >
                                    {{ t("footer.cookies") }}
                                </GdsLink>
                            </li>
                            <li>
                                <GdsLink
                                    variant="on-color"
                                    style="text-decoration: none"
                                    href="https://www.greenchoice.nl/klantenservice/voorwaarden/"
                                >
                                    {{ t("footer.terms") }}
                                </GdsLink>
                            </li>
                        </ul>
                    </GdsGridItem>
                    <GdsGridItem column-span="33">
                        <ul
                            class="space-y-[var(--gds-space-xs)] [font-size:var(--gds-font-size-body-s)]"
                        >
                            <li>
                                <h6
                                    class="[font-size:var(--gds-font-size-heading-6)]"
                                >
                                    {{ t("footer.productAdvice") }}
                                </h6>
                            </li>
                            <li>
                                <GdsLink
                                    variant="on-color"
                                    style="text-decoration: none"
                                    href="https://www.greenchoice.nl/mkb/product-advies/besparen/"
                                >
                                    {{ t("footer.save") }}
                                </GdsLink>
                            </li>
                            <li>
                                <GdsLink
                                    variant="on-color"
                                    style="text-decoration: none"
                                    href="https://www.greenchoice.nl/mkb/product-advies/zonnepanelen/"
                                >
                                    {{ t("footer.solarPanels") }}
                                </GdsLink>
                            </li>
                            <li>
                                <GdsLink
                                    variant="on-color"
                                    style="text-decoration: none"
                                    href="https://www.greenchoice.nl/mkb/product-advies/laadoplossingen/"
                                >
                                    {{ t("footer.chargingSolutions") }}
                                </GdsLink>
                            </li>
                        </ul>
                    </GdsGridItem>
                </GdsGrid>
            </GdsGridItem>
        </GdsGrid>
        <GdsGrid variant="main" is-centered class="py-[var(--gds-space-m)]">
            <GdsGridItem
                column-span="100"
                class="inline-flex items-center justify-between gap-[var(--gds-space-xxl)] sm:justify-start"
            >
                <a href="https://www.greenchoice.nl">
                    <img
                        src="/resources/static/images/logo/greenchoice-wit.svg"
                        alt="Greenchoice"
                        class="h-[48px]"
                    />
                </a>
                <div class="inline-flex gap-[var(--gds-space-l)] opacity-60">
                    <a
                        href="https://www.facebook.com/GreenchoiceNL/"
                        class="inline-flex items-center"
                    >
                        <GdsIcon
                            name="social-facebook"
                            variant="stroke"
                            type="icon"
                            title="facebook.com"
                            class="size-[24px]"
                        />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/greenchoice/"
                        class="inline-flex items-center"
                    >
                        <GdsIcon
                            name="social-linkedin"
                            variant="stroke"
                            type="icon"
                            title="linkedin.com"
                            class="size-[24px]"
                        />
                    </a>
                </div>
            </GdsGridItem>
        </GdsGrid>
    </div>
</template>

<script setup lang="ts">
import { onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import highlights from "~/json/FooterHighlights.json";
import {
    GdsButton,
    GdsGrid,
    GdsGridItem,
    GdsIcon,
    GdsLink,
} from "@greenchoice/ds";

const { t } = useI18n();
const selectedHighlight: Ref<unknown> = ref();

onMounted(() => {
    selectedHighlight.value = getRandomHighlight();
});

function getRandomHighlight() {
    if (highlights.items.length === 0) {
        return null;
    }

    const index = Math.floor(Math.random() * highlights.items.length);
    return highlights.items[index];
}
</script>
