import { defineStore, acceptHMRUpdate } from "pinia";
import { Ref, ref } from "vue";

export const useLoadersStore = defineStore("loaders", () => {
    // Set default values to true for calls which are active at mounting pages.
    const connections: Ref<boolean> = ref(true);
    const connectionsMeterreadings: Ref<boolean> = ref(true);
    const contactPersons: Ref<boolean> = ref(true);
    const contracts: Ref<boolean> = ref(true);
    const customer: Ref<boolean> = ref(true);
    const installmentAmounts: Ref<boolean> = ref(true);
    const invoiceGroups: Ref<boolean> = ref(true);
    const invoices: Ref<boolean> = ref(true);
    const mutations: Ref<boolean> = ref(true);
    const segments: Ref<boolean> = ref(true);
    const usage: Ref<boolean> = ref(true);
    const consumption: Ref<boolean> = ref(true);
    const login: Ref<boolean> = ref(false);
    const pdfBeingLoaded: Ref<string | undefined> = ref("");

    // Set default values to false for calls which are active after user interaction
    const customers: Ref<boolean> = ref(false);
    const addresses: Ref<boolean> = ref(false);
    const saveMutations: Ref<boolean> = ref(false);
    const meterReadings: Ref<boolean> = ref(false);

    const setAddresses = (value: boolean) => {
        addresses.value = value;
    };
    const setConnections = (value: boolean) => {
        connections.value = value;
    };
    const setConnectionsMeterreadings = (value: boolean) => {
        connectionsMeterreadings.value = value;
    };
    const setContactPersons = (value: boolean) => {
        contactPersons.value = value;
    };
    const setContracts = (value: boolean) => {
        contracts.value = value;
    };
    const setCustomer = (value: boolean) => {
        customer.value = value;
    };
    const setCustomers = (value: boolean) => {
        customers.value = value;
    };
    const setInstallmentAmounts = (value: boolean) => {
        installmentAmounts.value = value;
    };
    const setInvoiceGroups = (value: boolean) => {
        invoiceGroups.value = value;
    };
    const setInvoices = (value: boolean) => {
        invoices.value = value;
    };
    const setLogin = (value: boolean) => {
        login.value = value;
    };
    const setMutations = (value: boolean) => {
        mutations.value = value;
    };
    const setMeterReadings = (value: boolean) => {
        meterReadings.value = value;
    };
    const setSaveMutations = (value: boolean) => {
        saveMutations.value = value;
    };
    const setSegments = (value: boolean) => {
        segments.value = value;
    };
    const setUsage = (value: boolean) => {
        usage.value = value;
    };
    const setConsumption = (value: boolean) => {
        consumption.value = value;
    };

    /**
     * Tracks the ID of currently loaded PDF.
     */
    const trackLoadingPdf = (value: string) => {
        pdfBeingLoaded.value = value;
    };

    /**
     * Is used to tell any interesting parties that loading PDFs has completed
     */
    const completePdfLoading = () => {
        pdfBeingLoaded.value = undefined;
    };

    return {
        addresses,
        consumption,
        connections,
        connectionsMeterreadings,
        contactPersons,
        contracts,
        customer,
        customers,
        installmentAmounts,
        invoiceGroups,
        invoices,
        login,
        mutations,
        meterReadings,
        saveMutations,
        segments,
        usage,
        pdfBeingLoaded,
        setAddresses,
        setConsumption,
        setConnections,
        setConnectionsMeterreadings,
        setContactPersons,
        setContracts,
        setCustomer,
        setCustomers,
        setInstallmentAmounts,
        setInvoiceGroups,
        setInvoices,
        setLogin,
        setMutations,
        setMeterReadings,
        setSaveMutations,
        setSegments,
        setUsage,
        trackLoadingPdf,
        completePdfLoading,
    };
});

// https://pinia.vuejs.org/cookbook/hot-module-replacement.html#HMR-Hot-Module-Replacement-
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useLoadersStore, import.meta.hot));
}
