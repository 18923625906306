export default {
    meterstanden: "Meterstanden",
    bekijkAansluiting: "Bekijk aansluiting",
    meterstandenInvullen: "Meterstanden invullen",
    geenData: "Geen meterstanden gevonden",
    meterstandenInfo: {
        header: "meterstanden info",
    },
    vorigeStand: "Vorige stand",
    leveringNormaal: "Levering normaal",
    leveringLaag: "Levering laag",
    terugleveringNormaal: "Teruglevering normaal",
    terugleveringLaag: "Teruglevering laag",
    slaMeterstandenOp: "Sla meterstanden op",
    telwielen: "telwielen",
    opnamedatum: "Datum van laatst door de klant doorgegeven stand",
    slaMeterstandenOpVoorAansluitingen:
        "Sla meterstanden op voor {count} aansluiting. Aansluiting: {aansluitingen} | Sla meterstanden op voor {count} aansluitingen. Aansluitingen: {aansluitingen}",
    meterstandenDoorgeven: "Meterstanden doorgeven",
};
