import {
    type Connection,
    type ConnectionApiParameters,
    ConnectionType,
} from "@/@types/connection";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import type {
    UsageFrequency,
    UsageMode,
    UsageStartDate,
    UsageTotal,
    UsageUnit,
    UsageZoomLevel,
} from "@/@types/usage";
import moment from "moment";
import type { Moment } from "moment/moment";
import { MeterReading } from "@/@types/meterReading";
import { Segment } from "@/@types/segment";
import { Invoice, InvoiceParameters } from "@/@types/invoice";
import { CustomerOld, Permission } from "@/@types/customer";
import { useCustomerStore } from "@/store/customer.store";
import { Factuurgroep } from "%/types/flux.types";
import { Mutations } from "@/@types/mutations";
import { useLoadersStore } from "@/store/loaders.store";
import { computed } from "vue";
import { GovernmentLevy } from "@/@types/tariff";
import { useServicePartnerStore } from "@/store/partner.store";
import { useUserStore } from "@/store/user.store";
import { LoginType } from "@/composables/user";
import { getEarliestDateFromDates } from "@/utilities/date";
import { createDownloadLink, openBlobInNewWindow } from "@/utilities/file";
import { isDefined, isEmpty, isUndefined } from "@/utilities/validation";
import { Contract } from "@/class/contract";
import { TerminationCompensationResponse } from "@/@types/contract";
import { InstallmentsResponse } from "@/@types/installment";

export type BusinessUser = {
    type: LoginType.Business;
    token: string;
    success: 1 | 0;
    timestamp: number;
    name: string;
    rechten: Permission[];
} & (BusinessCustomer | ServicePartner);

export type BusinessCustomer = {
    /**
     * @description User is a business customer
     *
     * @example
     * let klant = true;
     */
    klant: true;
    number: number;
};

export type ServicePartner = {
    /**
     * @description User is a service employee
     *
     * @example
     * const klant = false;
     */
    klant: false;
    klanten: { klantnummer: number; klantnaam: string }[];
};

/**
 * @deprecated
 * `businessLegacyAxios` is deprecated and should only be used for calls to PHP.
 * Please use `businessAxios` instance for dotnet api calls.
 */
export const businessLegacyAxios = axios.create();
businessLegacyAxios.isCancel = axios.isCancel;

/**
 * `businessAxios` is the Axios for the dotnet business API.
 */
export const businessAxios: AxiosInstance = axios.create({
    baseURL: isDefined(import.meta.env)
        ? import.meta.env.VITE_BUSINESS_API
        : "",
});
businessAxios.isCancel = axios.isCancel;

let connectionAbort: AbortController,
    connectionMeterreadingsAbort: AbortController,
    usageAbort: AbortController;

/**
 * Logs in to the system using the provided username and password
 * and retrieves customer information.
 *
 * @param {string} username - The username for logging in.
 * @param {string} password - The password for logging in.
 * @returns {Promise} - A Promise that resolves when the customer information is retrieved.
 */
export async function logInAndRetrieveCustomerInfo(
    username: string,
    password: string,
): Promise<BusinessUser> {
    const userStore = useUserStore();
    const loaderStore = useLoadersStore();
    loaderStore.setLogin(true);

    return logIn(username, password)
        .then((response) => {
            if (response.klant && userStore.isBusiness) {
                // Only for customers, not servicepartners
                getAllCustomerData();
            }
            return response;
        })
        .finally(() => {
            loaderStore.setLogin(false);
        });
}

/**
 * Logs in a user with the provided username and password.
 *
 * @param {string} username - The username of the user.
 * @param {string} password - The password of the user.
 * @throws Error - If no authentication information is given.
 * @return {Promise<Object>} - A promise that resolves to the response data from the login API endpoint.
 */
export async function logIn(
    username: string,
    password: string,
): Promise<BusinessUser> {
    if (isEmpty(username) || isEmpty(password)) {
        throw new Error("No authentication information given");
    }

    return await businessLegacyAxios
        .post("/api/klant/login", {
            username: username,
            password: password,
        })
        .then((response: AxiosResponse<BusinessUser>) => {
            const customer = useCustomerStore();
            const partner = useServicePartnerStore();
            const user = useUserStore();

            customer.setPermissions(response.data.rechten);

            user.setToken(response.data.token);
            user.setType(LoginType.Business);

            if (response.data.klant) {
                customer.setCustomer({
                    customerNumber: response.data.number,
                    customerName: response.data.name,
                });
                partner.setIsPartner(false);
            } else {
                customer.setCustomer({
                    customerNumber: undefined,
                    customerName: response.data.name,
                });
                partner.setAvailableClients(response.data.klanten);
                partner.setIsPartner(true);
            }

            /**
             * Sets the default Token header for all Axios requests.
             *
             * @param {string} token - The authentication token to be set in the header.
             */
            businessLegacyAxios.defaults.headers.common["Token"] =
                response.data.token;

            return response.data;
        });
}

/**
 * Changes the password for a user.
 *
 * @param {string} username - The username of the user.
 * @param {string} password - The new password.
 * @param {string} passwordConfirmation - The confirmation of the new password.
 * @param {any} data - Additional data to send with the request.
 * @throws {Error} - If any of the required parameters are missing.
 * @returns {Promise<{ message: string }>} - A promise that resolves to an object containing a message property.
 */
export async function changePassword(
    username: string,
    password: string,
    passwordConfirmation: string,
    data: never,
): Promise<{ message: string }> {
    if (!username || !password || !passwordConfirmation || !data) {
        throw new Error(
            "Missing required information for changing the password.",
        );
    }

    return await businessLegacyAxios
        .post("/api/klant/login/change", {
            data: data,
            username: username,
            wachtwoord: password,
            wachtwoord_confirmation: passwordConfirmation,
        })
        .then<{ message: string }>((response) => {
            return response.data;
        });
}

/**
 * Function to handle the request for a password reset link.
 *
 * @param {string | null} username - The username of the user.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
export async function requestBusinessPasswordResetLink(
    username: string | null,
): Promise<AxiosResponse> {
    return await businessLegacyAxios.post("/api/klant/login/forget", {
        username: username,
    });
}

/**
 * Retrieves all customer data.
 *
 * @return {void}
 */
export function getAllCustomerData(): void {
    getCustomerData().then(() => {
        getReadFrequency();
        getContracts();
        getConnections();
        getConnectionsNeedingMeterReadings();
        getSegments();
        getInvoices();
        getInvoiceGroups();
        getMutations();
        getGovernmentLevies();
    });
}

export async function getCustomerData() {
    const loaderStore = useLoadersStore();
    loaderStore.setCustomer(true);

    // When the Token Header is undefined
    // add it to the headers for this request
    // and all requests after this request
    if (isUndefined(businessLegacyAxios.defaults.headers.common["Token"])) {
        const userStore = useUserStore();
        businessLegacyAxios.defaults.headers.common["Token"] = userStore.token;
    }

    return await businessLegacyAxios
        .get("/api/klant")
        .then((response: AxiosResponse<CustomerOld>) => {
            const customer = useCustomerStore();
            customer.setCustomer({
                bankIdentifierCode: response.data.bic!,
                houseNumber: response.data.huisnummer!,
                city: response.data.plaats!,
                country: response.data.land!,
                customerName: response.data.klantnaam!,
                customerNumber: response.data.klantnummer!,
                postalCode: response.data.postcode!,
                street: response.data.straat!,
                team: {
                    code: response.data.team!,
                    description: response.data.team_omschrijving!,
                },
                accountHolder: response.data.tenaamstelling!,
                bankAccountNumber: response.data.iban!,
                chamberOfCommerce: response.data.kvk!,
                houseNumberAddition: response.data.toevoeging ?? null,
            });
            customer.setPaymentMethod(response.data.betaalwijze);
            customer.setContactPersons(response.data.contactpersonen);
            loaderStore.setCustomer(false);
        })
        .catch((error) => {
            throw new Error(error);
        });
}

/**
 * Retrieves the connections for a customer based on the given parameters.
 *
 * @param {ConnectionApiParameters} parameters - The parameters for the connection retrieval.
 * @returns {Promise<{ connections: Connection[]; length: number }>} - A promise that resolves to an object containing the connections and the length of the connections.
 */
export async function getConnections(
    parameters: Partial<ConnectionApiParameters> = {},
): Promise<{ connections: Connection[]; length: number }> {
    if (connectionAbort) {
        connectionAbort.abort();
    }

    connectionAbort = new AbortController();
    const customerStore = useCustomerStore();
    customerStore.setConnections([]);
    const loaderStore = useLoadersStore();
    loaderStore.setConnections(true);

    return await businessLegacyAxios
        .get("/api/klant/aansluitingen", {
            signal: connectionAbort.signal,
            params: parameters,
        })
        .then<{ connections: Connection[]; length: number }>((response) => {
            customerStore.setConnections(response.data.aansluitingen);
            customerStore.setNumConnections(response.data.length);

            return {
                connections: response.data.aansluitingen,
                length: response.data.length,
            };
        })
        .catch((error) => {
            if (!businessLegacyAxios.isCancel(businessLegacyAxios)) {
                return error;
            }
        })
        .finally(() => {
            loaderStore.setConnections(false);
        });
}

export async function getConnectionsNeedingMeterReadings(): Promise<
    Connection[]
> {
    if (connectionMeterreadingsAbort) {
        connectionMeterreadingsAbort.abort();
    }

    connectionMeterreadingsAbort = new AbortController();

    const loaderStore = useLoadersStore();
    loaderStore.setConnectionsMeterreadings(true);

    return await businessLegacyAxios
        .get("/api/klant/aansluitingen/meterstanden", {
            signal: connectionMeterreadingsAbort.signal,
        })
        .then<Connection[]>((response) => {
            loaderStore.setConnectionsMeterreadings(false);
            const customerStore = useCustomerStore();
            customerStore.setConnectionsForMeterreadings(response.data);

            return response.data;
        })
        .catch((error) => {
            if (!businessLegacyAxios.isCancel(businessLegacyAxios)) {
                return error;
            }
        });
}

/**
 * Retrieves the meter readings for a given connection.
 *
 * @param {Connection} connection - The connection to retrieve the meter readings for.
 * @return {Promise<MeterReading[]>} A promise that resolves with the meter readings for the given connection.
 */
export async function getMeterReadings(
    connection: Connection,
): Promise<MeterReading[]> {
    const loaderStore = useLoadersStore();
    loaderStore.setMeterReadings(true);
    return await businessLegacyAxios
        .get("/api/klant/meterstanden/" + connection.EAN)
        .then<MeterReading[]>((response) => response.data)
        .finally(() => loaderStore.setMeterReadings(false));
}

export async function getContracts(): Promise<void> {
    const loaderStore = useLoadersStore();
    loaderStore.setContracts(true);
    return await businessLegacyAxios
        .get("/api/klant/contracten")
        .then<void>((response) => {
            const customerStore = useCustomerStore();
            customerStore.setContracts(
                response.data.map((contract: Contract): Contract => {
                    const c = new Contract();
                    c.aantal_segmenten = contract.aantal_segmenten;
                    c.actueel = contract.actueel;
                    c.begindatum = contract.begindatum;
                    c.einddatum = contract.einddatum;
                    c.id = contract.id;
                    c.nr = contract.nr;
                    c.omschrijving = contract.omschrijving;
                    c.product = contract.product;
                    c.propositie_id = contract.propositie_id;
                    c.segmenten = contract.segmenten;
                    c.status = contract.status;
                    c.tarieven = contract.tarieven;
                    c.tekendatum = contract.tekendatum;

                    return c;
                }),
            );

            loaderStore.setContracts(false);
        });
}

export async function getSegments() {
    const loaderStore = useLoadersStore();
    loaderStore.setSegments(true);
    return await businessLegacyAxios
        .get("/api/klant/segmenten")
        .then<Segment[]>((response) => {
            const customerStore = useCustomerStore();
            customerStore.setSegments(response.data);
            loaderStore.setSegments(false);
            return response.data;
        });
}

/**
 * Saves the installment amount
 *
 * @param {number} segmentId - The id of the segment you want to save the term amount
 * @param {number} installmentAmount - The new installment amount
 *
 * @returns {Promise<{error: 0 | 1, message: string}>} - A promise that resolves to an object containing the error status and message.
 */
export async function saveInstallmentAmount(
    segmentId: number,
    installmentAmount: number,
): Promise<{
    error: 0 | 1;
    message: string;
}> {
    return await businessLegacyAxios
        .post("/api/klant/segmenten/termijnbedrag", {
            segment_id: segmentId,
            termijnbedrag: installmentAmount,
        })
        .then((response) => response.data)
        .catch((error) => {
            throw new Error(error);
        });
}

export async function getInvoices(
    parameters: InvoiceParameters = {},
): Promise<{ facturen: Invoice[]; length: number }> {
    const loaders = useLoadersStore();
    loaders.setInvoices(true);

    parameters = Object.assign(
        {
            sort: "DESC",
            sortBy: "factuurdatum",
        },
        parameters,
    );

    return await businessLegacyAxios
        .get("/api/klant/facturen", {
            params: parameters,
        })
        .then<{ facturen: Invoice[]; length: number }>((response) => {
            const customerStore = useCustomerStore();
            customerStore.setInvoices(response.data.facturen);
            customerStore.setNumInvoices(response.data.length);

            return response.data;
        })
        .finally(() => {
            loaders.setInvoices(false);
        });
}

/**
 * Fetches invoice groups from the server and updates the corresponding store.
 * @async
 * @returns {Promise<Factuurgroep[]>} A promise that resolves to an array of invoice groups.
 */
export async function getInvoiceGroups(): Promise<Factuurgroep[]> {
    const loaderStore = useLoadersStore();
    loaderStore.setInvoiceGroups(true);

    return await businessLegacyAxios("/api/klant/facturen/groepen").then<
        Factuurgroep[]
    >((response) => {
        const customerStore = useCustomerStore();
        customerStore.setInvoiceGroups(response.data);
        loaderStore.setInvoiceGroups(false);
        return response.data;
    });
}

/**
 * Retrieves the usage export for a given connection.
 *
 * @param {Connection} connection - The connection for which to retrieve the usage export.
 * @return {Promise<void>} - A promise that resolves when the usage export is retrieved and a download link is created.
 */
export async function getUsageExport(connection: Connection): Promise<void> {
    return await businessLegacyAxios
        .get("/api/klant/verbruik/export", {
            params: {
                ean: connection.EAN,
                format: "csv",
            },
        })
        .then((response) => {
            createDownloadLink(response);
        })
        .catch((error) => {
            throw new Error(error);
        });
}

/**
 * Fetches the usage data for the given connections.
 *
 * @param {Connection[]} connections - The list of connections to fetch usage data for.
 * @param {UsageZoomLevel} [zoom="maand"] - The zoom level for the usage data (e.g. "maand", "jaar").
 * @param {"now" | string | number} [date="now"] - The starting date for the usage data. Default is "now".
 * @param {UsageMode} mode
 * @param {boolean} group
 * @param {UsageUnit} product
 * @returns {Promise<object>} - A Promise that resolves to the usage data object.
 */
export async function getUsage(
    connections: Connection[],
    zoom: UsageZoomLevel = "maand",
    date: UsageStartDate = "now",
    mode: UsageMode = "netto",
    group: boolean = false,
    product: UsageUnit = "stroom",
): Promise<{
    usage: { series: []; xAxis: NonNullable<unknown> };
    total: UsageTotal;
}> {
    let start: Moment;

    if (usageAbort) {
        usageAbort.abort();
    }

    if (connections.length < 1) {
        return { total: { levering: 0 }, usage: { series: [], xAxis: {} } };
    }

    usageAbort = new AbortController();

    if (zoom === "jaar") {
        start = getEarliestDateFromDates(
            connections.map((connection: Connection) =>
                connection.connectionType === ConnectionType.Existing &&
                connection.edsn_begindatum
                    ? connection.edsn_begindatum
                    : "",
            ),
        );
    } else {
        // Parse to moment object and format correctly
        start = date === "now" ? moment() : moment(date);
    }

    const loaderStore = useLoadersStore();
    loaderStore.setUsage(true);

    return await businessLegacyAxios
        .get("/api/klant/verbruik", {
            signal: usageAbort.signal,
            params: {
                EAN: connections.map(
                    (connection: Connection) => connection.EAN,
                ),
                start: start.minutes(0).format("YYYY-MM-DD HH:mm"),
                zoom: zoom,
                mode: mode,
                group: group,
                product: product,
            },
        })
        .then((response) => {
            loaderStore.setUsage(false);
            return {
                usage: {
                    series: response.data.series ?? [],
                    xAxis: response.data.xAxis ?? {},
                },
                total: response.data.totals ?? { levering: 0 },
            };
        })
        .catch(() => {
            return {
                usage: {
                    series: [],
                    xAxis: {},
                },
                total: {
                    levering: 0,
                },
            };
        });
}

/**
 * Saves the new read frequency for a user.
 *
 * @param {UsageFrequency} newReadFrequency - The new read frequency to be saved.
 *
 * @returns {Promise<{error: 0 | 1, message: string}>} - A promise that resolves to an object containing the error status and message.
 */
export async function saveReadFrequency(
    newReadFrequency: UsageFrequency,
): Promise<{
    error: 0 | 1;
    message: string;
}> {
    return await businessLegacyAxios
        .post("/api/klant/aansluitingen/uitleesfrequentie", {
            interval: newReadFrequency,
        })
        .then((response) => response.data);
}

/**
 * Retrieves the current read frequency for the customer.
 *
 * @returns {string} The current read frequency.
 */
export async function getReadFrequency(): Promise<{
    error: 0 | 1;
    interval: UsageFrequency;
    editable: boolean;
}> {
    return await businessLegacyAxios
        .get("/api/klant/aansluitingen/uitleesfrequentie")
        .then((response) => response.data);
}

/**
 * Logs out the current user by making a GET request to the "/api/klant/logout" endpoint.
 * Removes the "timestamp" item from the local storage and resets the Client object in the VueX store.
 *
 * @returns {Promise<boolean>} A Promise that resolves to a boolean value indicating if the logout was successful.
 */
export async function logOutBusinessUser(): Promise<boolean> {
    const partnerStore = useServicePartnerStore();
    const customerStore = useCustomerStore();

    return businessLegacyAxios
        .get("/api/klant/logout")
        .then<boolean>((response) => {
            if (response.data) {
                customerStore.$reset();
                partnerStore.$reset();
            }
            return response.data as boolean;
        });
}

/**
 * Retrieves mutations from the server.
 * @returns {Promise<*>} A promise that resolves to the fetched mutations.
 * @throws {Error} If an error occurs during the request.
 */
export async function getMutations() {
    const loaderStore = useLoadersStore();
    loaderStore.setMutations(true);
    return await businessLegacyAxios
        .get("/api/klant/mutaties")
        .then<Mutations>((response: AxiosResponse<Mutations>) => {
            loaderStore.setMutations(false);
            const customerStore = useCustomerStore();
            customerStore.setSignedOnMutations(response.data.aanmeldingen);
            customerStore.setSignedOffMutations(response.data.afmeldingen);
            return response.data;
        })
        .catch((error) => {
            throw new Error(error);
        });
}

export async function getAnnouncements() {
    return await businessLegacyAxios
        .get("/api/mededelingen")
        .then((response) => response.data)
        .catch(() => {});
}

/**
 * Retrieves the government levies from the API endpoint.
 *
 * @returns {Promise<Object>} A promise that resolves with the government levies data.
 */
export async function getGovernmentLevies(): Promise<GovernmentLevy[]> {
    return await businessLegacyAxios
        .get("/api/klant/contracten/overheidsheffingen")
        .then<GovernmentLevy[]>((response) => {
            const customerStore = useCustomerStore();
            customerStore.setGovernmentLevies(response.data);
            return response.data;
        });
}

/**
 * Either retrieves an invoice or contract as a PDF and opens it in a new window, or makes it available for downloading.
 *
 * @param id - The id of the contract or invoice to retrieve.
 * @param name - The name to use for the PDF file.
 * @param type - The type of document to retrieve
 * @param download - Whether to download the document or open it in a new window.
 */
export async function loadOrOpenPdf(
    id: string,
    name: string,
    type: "invoice" | "contract",
    download = false,
) {
    let url = "/api/klant/document/";

    const loaders = useLoadersStore();

    // REFACTOR: Views now make assumptions about the 'd' and 'f'. We should provide specific
    // functions to avoid views from having to know about these magic strings
    loaders.trackLoadingPdf(download ? "d-" + id : "f-" + id);

    switch (type.toLowerCase()) {
        case "invoice":
            url += "factuur/" + id;
            break;

        case "contract":
            url += "contract/" + id;
    }

    await businessLegacyAxios
        .get(url)
        .then((response) => {
            if (response.data) {
                if (download) {
                    const a = document.createElement("a");
                    a.href = "data:application/pdf;base64," + response.data;
                    a.target = "_self";
                    a.download = name + ".pdf";
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    openBlobInNewWindow(response.data, name);
                }
            }

            loaders.completePdfLoading();
        })
        .catch(() => {
            loaders.completePdfLoading();
        });
}

export function hasPermission(
    right: string,
    elevation: "raadpleeg" | "wijzig" = "raadpleeg",
): boolean {
    const customerStore = useCustomerStore();

    return customerStore.permissions.some(
        (permission: Permission) =>
            permission.omschrijving === right &&
            permission.permissie.includes(elevation),
    );
}

/**
 * Determines whether the customer has gas connections.
 *
 * @function hasGasConnections
 * @returns {boolean} - Returns true if the customer has gas connections, false otherwise.
 */
export const hasGasConnections = (): boolean => {
    return computed(() => {
        const customerStore = useCustomerStore();

        return customerStore.contracts.some(
            (contract: Contract) =>
                contract.actueel &&
                (contract.product.includes("GAS") ||
                    contract.product.includes("CMB")),
        );
    }).value;
};

/**
 * Determines if the customer has electricity connections.
 *
 * @returns {boolean} True if the customer has electricity connections, false otherwise.
 */
export const hasElectricityConnections = (): boolean => {
    return computed(() => {
        const customerStore = useCustomerStore();
        return customerStore.contracts.some(
            (contract: Contract) =>
                contract.actueel &&
                ["ELK", "CMB"].some((el) => contract.product.includes(el)),
        );
    }).value;
};

export async function getEstimatedTerminationCompensation(
    cancellationDate: string,
    eans: { ean: string }[],
    emailAddress: string,
): Promise<TerminationCompensationResponse> {
    return await businessLegacyAxios
        .post("/api/klant/contracten/opzegvergoeding/bereken", {
            cancellationDate: cancellationDate,
            eans: eans,
            emailAddress: emailAddress,
        })
        .then((response) => response.data);
}

export async function getInstallments(): Promise<InstallmentsResponse> {
    const loaderStore = useLoadersStore();
    const customerStore = useCustomerStore();

    loaderStore.setInstallmentAmounts(true);

    let customerId: number = customerStore.customer.customerNumber;
    setBearerToken();

    // ToDo: remove hard coded header and customer ID
    //  This will be done in PBI 192677
    businessAxios.defaults.headers.common.Authorization =
        "Bearer " + "mgc-789-dat";
    customerId = 125;

    return await businessAxios
        .get("/api/customers/" + customerId + "/installments")
        .then((response: AxiosResponse<InstallmentsResponse>) => response.data)
        .catch((error) => {
            throw new Error(error);
        })
        .finally(() => {
            loaderStore.setInstallmentAmounts(false);
        });
}

export function setBearerToken(): void {
    const userStore = useUserStore();

    businessAxios.defaults.headers.common.Authorization =
        "Bearer " + userStore.token;
}
