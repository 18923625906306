<template>
    <div
        class="bg-[var(--gds-color-bg-brand-1)]"
        :class="heroPull"
        :style="{ 'min-height': props.height + 'px' }"
    >
        <GdsGrid variant="main" is-centered>
            <GdsGridItem>
                <header data-testid="hero">
                    <div
                        class="flex-inline relative py-7"
                        :class="{
                            container: props.container,
                            'mx-auto': props.centered && props.container,
                        }"
                    >
                        <gz-badge
                            v-if="customerStore.customer.customerNumber"
                            class="absolute right-0 z-10 mr-0"
                            data-testid="klantnummer"
                        >
                            {{ customerStore.customer.customerNumber }}
                        </gz-badge>
                        <h1 v-if="heroPrefix || heroTitle" class="relative">
                            <span
                                v-if="heroPrefix"
                                data-testid="titlePrefix"
                                class="absolute top-0 z-10 inline-block -rotate-5 bg-green-800 px-2 pb-1 pt-2 font-bebas-neue text-4xl font-bold text-white dark:bg-white dark:text-green-800"
                            >
                                {{ heroPrefix }}
                            </span>
                            <span
                                v-if="heroTitle"
                                data-testid="titleContent"
                                class="mx-6 mb-2 mt-12 inline-block bg-white px-2 pt-2 font-bebas-neue text-6xl font-bold text-green-800 shadow-sm-br dark:bg-green-500 dark:text-white"
                            >
                                {{ heroTitle }}
                            </span>
                        </h1>
                    </div>
                </header>
            </GdsGridItem>
        </GdsGrid>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import GzBadge from "../badge/GzBadge.vue";
import { useRoute } from "vue-router";

import { useCustomerStore } from "@/store/customer.store";
import { useI18n } from "vue-i18n";
import { GdsGrid, GdsGridItem } from "@greenchoice/ds";

const props = defineProps({
    translate: {
        required: false,
        type: Boolean,
        default: false,
    },
    prefix: {
        required: false,
        type: String,
        default: "",
    },
    height: {
        required: false,
        type: Number,
        default: 165,
    },
    container: {
        required: false,
        type: Boolean,
        default: false,
    },
    centered: {
        required: false,
        type: Boolean,
        default: false,
    },
});

const route = useRoute();
const { t } = useI18n();
const customerStore = useCustomerStore();

/**
 * Route name based on translation prop
 * @type {import("vue").ComputedRef<string>}
 */
const name = computed((): string =>
    props.translate && route.name
        ? t(route.name as string)
        : (route.name as string),
);

/**
 * Hero configuration from route meta data
 * @type {import("vue").ComputedRef<object>}
 */
const heroConfig = computed(() => {
    let hasConfig = route.meta.page && route.meta.page.hero;
    return hasConfig
        ? route.meta.page!.hero
        : {
              pull: false,
          };
});

/**
 * Hero prefix from props and route meta data
 * @type {import("vue").ComputedRef<string | null>}
 */
const heroPrefix = computed(() => {
    if (props.prefix) {
        return props.prefix;
    } else if (hasConfig("prefix")) {
        return hasConfig("prefix");
    } else {
        if (!name.value) return null;
        let n = name.value.split(" ");
        return n.length > 1 ? n[0] : null;
    }
});

/**
 * Hero title from props and route metad data
 * @type {import("vue").ComputedRef<string>}
 */
const heroTitle = computed(() => {
    if (!name.value) return null;
    if (props.prefix || hasConfig("prefix")) {
        return name.value;
    }
    let n = name.value.split(" ");
    return n.length > 1 ? n[1] : name.value;
});

/**
 * Hero pull from props and route meta data
 * @type {import("vue").ComputedRef<string>}
 */
const heroPull = computed(() => {
    let pullSize = hasConfig("pull");
    const sizeToClassMapping = {
        xs: "",
        sm: "-mb-[3.4rem] pb-[3.4rem]",
        md: "-mb-16 pb-16",
        lg: "-mb-24 pb-24",
        xl: "",
    };

    return sizeToClassMapping[pullSize] ?? null;
});

/**
 * Check if the hero configuration has a specific property
 * @function
 * @param {string} property - The property to check
 * @return {string | boolean} - The value of the property or false if not exists
 */
function hasConfig(property: string) {
    return Object.prototype.hasOwnProperty.call(heroConfig.value!, property)
        ? heroConfig.value![property]
        : false;
}
</script>
