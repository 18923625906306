import { UsageFrequency } from "@/@types/usage";

type ConnectionProperties = {
    begindatum: string;
    einddatum: string;
    ety_klant_info: string;
    ety_volgorde: number;
    id: number;
    naam: string;
    omschrijving: string;
};

export enum ConnectionType {
    Existing = "existing",
    MeterReadings = "meterReadings",
    Registered = "registered",
}

export enum ProductType {
    Unknown = "Unknown",
    Electricity = "Electricity",
    Gas = "Gas",
}

enum MeasurementUnit {
    KWH = "KWH",
    M3 = "M3",
}

enum MeasurementDirection {
    Delivery = "Levering",
    ReturnDelivery = "Teruglevering",
}

enum TariffZone {
    Low = "L",
    Normal = "N",
}

enum CounterIdentification {
    usageTariffNormal = "1.8.1",
    usageTariffLow = "1.8.2",
    returnDeliveryTariffNormal = "2.8.1",
    returnDeliveryTariffLow = "2.8.2",
}

type ConnectionCountingWheel = {
    aantal_telwielen: number;
    id: number;
    meeteenheid: MeasurementUnit;
    meetrichting: MeasurementDirection;
    tariefzone: TariffZone;
    telwerkidentificatie: CounterIdentification;
};

export type ConnectionApiParameters = {
    adres: string;
    begindatum: string;
    EAN: string;
    einddatum: string;
    end: number;
    global: string;
    grootboekrekening: string;
    kostenplaats: string;
    metertype: string;
    product: string | null;
    segmentnaam: string | null;
    sort: "DESC" | "ASC";
    sortBy: string;
    start: number;
    verbruikssegment: string | null;
};

export type Connection = {
    aansluitadres: string;
    adres: string;
    bevestigd: boolean;
    ean: string;
    EAN: string;
    gas: boolean;
    gemachtigd: boolean;
    id: number;
    ingangsdatum: string;
    inhuizing: string | null;
    interval: UsageFrequency | null;
    mdf_id: string;
    meternummer: string;
    meterstanden: {
        valid: boolean;
        filled: boolean;
    };
    naam: string;
    product: "Gas" | "Stroom";
    productsoort: "GAS" | "ELK";
    segment: number | null;
    slim: boolean;
    status: string;
    stroom: boolean;
    telemetrisch: boolean;
    teruglevering: boolean;
    uitleesbaar: boolean;
    verblijfsfunctie: boolean;
    verbruikssegment: "KVB" | "GVB" | "Kleinverbruik" | "Grootverbruik";
} & (ExistingConnection | RegisteredConnection | MeterreadingsConnection);

export type ExistingConnection = {
    aanmaakdatum: string;
    aansluitwaarde: string;
    actief: boolean;
    adres: string;
    allocatie_methode: "JRL" | "CMB" | "MND" | "Geprofileerd";
    bcl_ean_hoofd: string;
    bcl_id: number;
    begindatum: string;
    belastingcluster_omschrijving: string;
    captacode: string;
    coa_type_aansluiting: string;
    coa_type_aansluiting_oms: string;
    coa_verblijfsfunctie_oms: string;
    connectionType: ConnectionType.Existing;
    contract_id: number;
    contract_type: string;
    contract_naam: string;
    contractcapaciteit: string | null;
    datum_welkomstbrief: string;
    edsn_begindatum: string | null;
    eigenschappen: ConnectionProperties[];
    einddatum: string;
    einddatum_afkoelperiode: string;
    fysieke_status: string;
    fysiekecapaciteit: string;
    fysiekestatus: string;
    gebruik_aansluiting: string;
    huisnummer: number;
    huisnummer_toevoeging: string;
    klanteigenschappen: [];
    klantnummer: number;
    leveringsrichting: string;
    leveringsstatus: string;
    machtiging_status: number;
    meterstand?: [];
    meterstanden_nodig: boolean;
    metertype: string;
    nr: number;
    omschrijving: string;
    ontvangstdatum: string;
    p4gemachtigd: string;
    plaats: string;
    postcode: string;
    profielcode: string;
    propositie_id: number;
    segment_id: number;
    sem_begin: string;
    sja_dal: number;
    sja_normaal: number;
    sji_dal: number;
    sji_normaal: number;
    straat: string;
    switchtype: string;
    switchtype_omschrijving: string;
    team: string;
    tekendatum: string;
    telwerken: ConnectionCountingWheel[];
    volgend_termijn: string;
    volgende_afrekening: string;
    wijzebemetering: string;
    wijzebemetering_afkorting: "JRL" | "MND";
};

export type RegisteredConnection = {
    aantal_telwielen: {
        ll: number;
        ln: number;
        tl: number;
        tn: number;
    };
    connectionType: ConnectionType.Registered;
    contract: number | null;
    factuurgroep: number | null;
    inhuizing: number | null;
    kenmerken: [null | string, null | string, null | string];
    levering: "enkel" | "dubbel";
    meterstand: {
        ll_ingave: boolean;
        ln_ingave: boolean;
        tl_ingave: boolean;
        tn_ingave: boolean;
    };
    type: "SLIM" | "ANDERS";
};

export type MeterreadingsConnection = {
    actief: boolean;
    aantal_telwielen: {
        ln: number | null;
        ll: number | null;
        tn: number | null;
        tl: number | null;
    };
    aansluitwaarde: string | null;
    connectionType: ConnectionType.MeterReadings;
    eigenschappen: ConnectionProperties[];
    klanteigenschappen: [];
    machtiging_status: number;
    meterstand: {
        datum_laatste: string;
        ll_ingave: boolean;
        ll_laatste: number | null;
        ln_ingave: boolean;
        ln_laatste: number | null;
        tl_ingave: boolean;
        tl_laatste: number | null;
        tn_ingave: boolean;
        tn_laatste: number | null;
        begin: {
            normaal: number | null;
            laag: number | null;
            teruglevering_normaal: number | null;
            teruglevering_laag: number | null;
        };
        eind: {
            normaal: number | null;
            laag: number | null;
            teruglevering_normaal: number | null;
            teruglevering_laag: number | null;
        };
        verbruik: {
            normaal: number | null;
            laag: number | null;
            teruglevering_normaal: number | null;
            teruglevering_laag: number | null;
        };
        periode: boolean;
    };
    meterstanden_nodig: boolean;
    telwerken: ConnectionCountingWheel[];
};

export type ProducerConnection = {
    ean: string;
    productType: ProductType;
    street: string;
    houseNumber: number;
    houseNumberSuffix: string;
    postalCode: string;
    city: string;
};
