export default {
    aanmeldingen: "Aanmeldingen",
    afmeldingen: "Afmeldingen",
    aantalAansluitingenGeselecteerdPerAdres:
        "{geselecteerd} van {totaal} aansluitingen geselecteerd",
    geenAansluitingenGevondenVoorDitAdres:
        "Geen aansluitingen gevonden voor dit adres",
    totaalAansluitingenGeselecteerd: "{totaal} aansluitingen geselecteerd",
    // Mag weg
    addnewsegments:
        "Kies een bestaand segment of geef een nieuwe segmentnaam in",
    addressfound: "Het adres is gevonden en de velden zijn verder aangevuld",
    addressnotfound: "Adres niet gevonden",
    agree: "Na bevestiging gaat u akkoord met de afmelding van de aansluiting(en) met EAN",
    clusterenergiebelasting: "Cluster energiebelasting",
    connectionaddress: "Aansluitadres",
    connectioninformation: "Aansluitgegevens",
    contract: "Contract",
    currentaddressinfo: "Huidige adresgegevens",
    description:
        "Hieronder ziet u recente aan- en afmeldingen. Wilt u een mutatie doorgeven? Selecteer dan 'Aanmelden' of 'Afmelden' in het rechter menu.",
    factuurgroep: "Factuurgroep",
    factuurreferentie: "Factuurreferentie",
    failedsavinginhuizing:
        "De bevestiging is helaas niet gelukt. Neem a.u.b. contact op met onze klantenservice.",
    feedinoffpeakmeter: "Teruglevering laag",
    feedinpeakmeter: "Teruglevering normaal",
    feedinsinglemeter: "Teruglevering normaal",
    fillallrecorddates:
        "Kies hier een datum die geldt voor alle aansluitingen. Zo hoef je niet elk datumveld langs, maar kan dit snel in \u00e9\u00e9n keer.",
    fillinmeterreadings:
        "De gewenste ingangsdatum ligt binnen twee weken. Geef uw meterstanden door bij stap 3.",
    finance: "Gegevens aansluiting",
    firstfillinaddress:
        "Vul een postcode en huisnummer in. De aansluitingen worden hier weergegeven na het ophalen van de adres- en aansluitgegevens.",
    firstselectconnections: "Selecteer \u00e9\u00e9n of meerdere aansluitingen",
    hasnoresidencefunction: "Geen verblijfsfunctie",
    hasresidencefunction: "Verblijfsfunctie",
    housing: "Inhuizing",
    housingaddressintro:
        "Vul uw postcode en huisnummer in. De overige adresgegevens met betreffende aansluitingen worden automatisch gevuld. Vervolgens kiest u welke aansluiting(en) u wilt toevoegen.",
    housingdescription:
        "U kunt in 4 eenvoudige stappen een adres aanmelden. Voer de gevraagde gegevens in van het adres, de aansluiting(en), eventuele meterstanden en controleer de gegevens voor het verzenden.",
    housinginvoiceintro: "Facturatie introductie - wat vult de klant hier in?",
    housingmeterreadingsintro:
        "Om een juiste beginstand voor uw energieverbruik vast te stellen, hebben wij zo snel mogelijk uw meterstanden nodig. Zo voorkomt u dat we uw meterstanden moeten schatten en u mogelijk bijbetaalt op uw nota. U vult alleen de tabellen in die voor u van toepassing zijn.",
    housings: "Recente aanmeldingen",
    inhuizingagree: "Ik bevestig dat ik akkoord ga met de aanmelding van",
    inhuizingdate: "Ingangsdatum",
    inhuizingstep1: "Stap 1: Nieuwe inhuizing",
    inhuizingstep2: "Stap 2: Aansluitgegevens",
    inhuizingstep3: "Stap 3: Meterstanden",
    inhuizingstep4: "Stap 4: Bevestiging",
    invoicegroup: "Factuurgroep",
    issmartmeter:
        "Dit betreft een slimme meter. Wij zullen de meterstanden uitlezen bij de netbeheerder. Voor deze aansluiting hoeft u dus geen meterstanden door te geven.",
    kenmerk: "Kenmerk {nummer}",
    keyhandover: "Sleuteloverdracht",
    meterreadings: "Meterstanden",
    moving: "Verhuizen",
    movingintroduction: "Geef hier uw verhuizing door.",
    newaddressinfo: "Nieuwe adresgegevens",
    newhousing: "Nieuwe inhuizing",
    newmoving: "Verhuizing",
    newrelocation: "Nieuwe uithuizing",
    newsegment: "Nieuw segment",
    nextstep: "Volgende stap",
    nomutationsfound:
        "Er zijn nog geen mutaties gevonden. Na het doorvoeren van een mutatie, komt deze hier in een overzicht te staan.",
    offpeakmeter: "Levering laag",
    peakmeter: "Levering normaal",
    postalcode: "Postcode",
    recorddate: "Opnamedatum",
    recorddates: "Opnamedatum",
    relocation: "Uithuizing",
    relocationdescription:
        "U kunt in 3 eenvoudige stappen een adres afmelden. Filter de aansluitingen (indien nodig), selecteer de gewenste aansluitingen door het knopje om te zetten, kies een gewenste einddatum, voer de meterstanden in en controleer de gegevens voor het verzenden.",
    relocationmeterreadingsintro: "Vul hieronder de meterstanden in",
    relocations: "Recente afmeldingen",
    relocationsignoffconnection:
        "Vul de postcode en het huisnummer in van het adres dat u wilt aanmelden",
    residence_function: "Verblijfsfunctie",
    residence_function_available: "Ja",
    residencefunction: "Verblijfsfunctie",
    residencefunctiontooltip:
        "Een adres heeft een verblijfsfunctie als er mensen kunnen verblijven, zoals woningen, kantoorpanden of fabrieken. Als het adres een verblijfsfunctie heeft, heeft u recht op vermindering energiebelasting. Een adres zonder verblijfsfunctie is bijvoorbeeld een garagebox, volkstuintje of informatieborden.",
    segment: "Segment",
    selectaddress: "Selecteer een adres",
    selectallconnections: "(De)selecteer alle aansluitingen",
    selectconnection: "Selecteer een aansluiting",
    selectcontact: "Selecteer een contactpersoon",
    selectcontract: "Selecteer contract",
    signoffconnection: "Afmelden aansluiting",
    singlemeter: "Levering normaal",
    skipfillinmeterreadings:
        "De gewenste einddatum ligt op meer dan twee weken na heden. U ontvangt bericht wanneer u de meterstanden moet doorgeven.",
    successsavinginhuizing:
        "Dank voor het doorgeven van uw aanmelding(en). Wij gaan deze voor u verwerken.",
    successsavinguithuizing:
        "Dank voor het doorgeven van uw afmelding(en). Wij gaan deze voor u verwerken.",
    supplier: "Leverancier aanwezig",
    tooltip: "Download de mutaties naar Excelbestand",
    uithuizingagree: "Ik bevestig dat ik akkoord ga met de afmelding van",
    uithuizingdate: "Einddatum",
    uithuizingfillinmeterreadings:
        "De gewenste einddatum ligt binnen twee weken. Geef uw meterstanden door bij \u2018stap 2\u2019.",
    uithuizingskipfillinmeterreadings:
        "De sleuteloverdracht vindt plaats over meer dan twee weken na heden. U ontvangt bericht wanneer u de meterstanden moet doorgeven. Heeft u een slimme meter, dan lezen wij de standen automatisch uit. Ga door naar stap 4.",
    uithuizingstep1: "Stap 1: Afmelden aansluiting",
    uithuizingstep2: "Stap 2: Meterstanden",
    uithuizingstep3: "Stap 3: Bevestiging",
    uithuizingstep4: "Stap 3: Overzicht",
    verhuizingdate: "Sleuteloverdracht",
    verhuizingstep1: "Stap 1. Huidige adresgegevens",
    verhuizingstep2: "Stap 2. Meterstanden",
    verhuizingstep3: "Stap 3. Nieuwe adresgegevens",
    verhuizingstep4: "Stap 4. Bevestiging",
    verhuizingsuccess:
        "Uw verhuizing is succesvol verzonden. Wanneer uw verhuizing is verwerkt ontvangt u hiervan bericht op het bij ons bekende e-mailadres. Wij zetten hier alle benodigde informatie voor u op een rij.",
    yes: "Ja",
};
