import axios, { AxiosResponse } from "axios";

/**
 * Creates a temporary file to download content
 * @param response
 * @param filename
 */
export function createDownloadLink(
    response: AxiosResponse<string>,
    filename: string | null = null,
): void {
    // Create URL blob
    const url: string = window.URL.createObjectURL(
        new Blob([response.data], {
            type: response.headers["content-type"].split(";")[0] ?? "text-csv",
        }),
    );

    // Create link
    const link: HTMLAnchorElement = document.createElement("a");

    // Get filename from Content Disposition header
    const name: string =
        filename ??
        response.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0];

    // Assign Blob URL to link
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

/**
 * Generates a blob URI for the given data and opens it in a new window.
 *
 * @param {string} data - The base64 encoded data.
 * @param {string} [name] - The name of the file (optional).
 * @return {void}
 */
export function openBlobInNewWindow(data: string, name: string = ""): void {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], {
        type: "application/pdf;base64",
    });

    const popup = window.open(
        URL.createObjectURL(file),
        "_blank",
        "width=800,height=600,toolbar=0,menubar=0,location=0",
    );
    setTimeout(() => {
        popup!.document.title = name;
    }, 100);
}

export async function getPDF(
    id: number,
    name: string,
    type: "invoice" | "contract" = "invoice",
    download: boolean = false,
) {
    let url = "/api/klant/document/";

    switch (type) {
        case "invoice":
            url += "factuur/" + id;
            break;

        case "contract":
            url += "contract/" + id;
    }

    return await axios
        .get(url)
        .then((response) => {
            if (response.data) {
                if (download) {
                    createDownloadLink(response.data);
                } else {
                    openBlobInNewWindow(response.data, name);
                }
            }
        })
        .catch(() => {});
}
