import { logOutBusinessUser } from "@/composables/customer";
import { logOutProducerUser } from "@/composables/producer";
import { RouteLocation, RouteLocationNormalized, useRouter } from "vue-router";
import { isUndefined } from "@/utilities/validation";
import { useUserStore } from "@/store/user.store";

export enum LoginType {
    Business = "zakelijk",
    Producer = "producenten",
}

export enum TypeOfUser {
    Customer = "Customer",
    ServicePartner = "ServicePartner",
}

export async function logOut(
    currentRoute: RouteLocationNormalized,
): Promise<boolean> {
    const userStore = useUserStore();
    const router = useRouter();

    let result = false;

    const currentPath: string | null =
        isUndefined(currentRoute.meta.noAuth) && currentRoute.path !== "/"
            ? currentRoute.path
            : null;

    const loginRoute: Partial<RouteLocation> = {
        path: userStore.isBusiness ? "/zakelijk/login" : "/producenten/login",
        query: currentPath ? { path: currentPath } : {},
    };

    if (userStore.isProducer) {
        result = await logOutProducerUser();
    } else if (userStore.isBusiness) {
        result = await logOutBusinessUser();
    }

    userStore.$reset();

    if (result) {
        await router.push(loginRoute as RouteLocation);
    }

    return result;
}
