<template>
    <div
        :class="classes"
        class="h-screen w-full items-center md:flex md:h-10 md:w-auto"
    >
        <ul
            :class="{ highlight: highlight }"
            class="mb-0 mt-4 flex flex-col items-center md:mt-0 md:flex-row md:font-medium"
        >
            <slot />
        </ul>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
    highlight: {
        required: false,
        default: false,
        type: Boolean,
    },
    visible: {
        required: false,
        default: false,
        type: Boolean,
    },
    position: {
        required: false,
        type: String,
        default: null,
    },
    order: {
        required: false,
        type: String,
        default: null,
    },
});

const classes = computed(() => {
    let classes: string[] = [];

    switch (props.position) {
        case "right":
            classes.push("ml-auto");
            break;
        case "left":
            classes.push("mr-auto");
            break;
        case "center":
            classes.push("mx-auto");
            break;
    }

    switch (props.order) {
        case "1":
            classes.push("md:order-1");
            break;
        case "2":
            classes.push("md:order-2");
            break;
        case "3":
            classes.push("md:order-3");
            break;
        case "4":
            classes.push("md:order-4");
            break;
        case "5":
            classes.push("md:order-5");
            break;
    }

    !props.visible ? classes.push("hidden") : null;

    return classes.join(" ");
});
</script>
