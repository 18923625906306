<template>
    <div
        ref="dropdown"
        :class="{
            hidden: !dropdownVisible,
            'left-0': left,
            'right-0': right,
            '-twtop-1/2 -twleft-1/2 -translate-x-1/2 -translate-y-1/2': center,
            'w-full': fullwidth,
        }"
        class="absolute z-50 block min-w-fit list-none divide-y divide-gray-100 rounded bg-white text-base shadow dark:divide-gray-600 dark:bg-gray-700"
        data-testid="dropdown"
    >
        <ul aria-labelledby="dropdown" class="dropdown py-1">
            <li
                v-for="route in dropdownRoutes"
                :key="route.name"
                @click="handleItemClick"
            >
                <router-link
                    :to="route"
                    class="block px-4 py-2 font-source-sans text-gray-700 hover:bg-green-800 hover:text-white dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                    target="_self"
                    :data-testid="route.name"
                >
                    {{ t(route.name) }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import {
    computed,
    getCurrentInstance,
    onBeforeUnmount,
    onMounted,
    ref,
    watch,
} from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
    routes: {
        required: true,
        type: Array,
    },
    visible: {
        required: true,
        type: Boolean,
    },
    left: {
        required: false,
        type: Boolean,
    },
    center: {
        required: false,
        type: Boolean,
    },
    right: {
        required: false,
        type: Boolean,
    },
    fullwidth: {
        required: false,
        type: Boolean,
    },
    navKey: {
        required: false,
        type: String,
        default: "dropdown",
    },
});

const { t } = useI18n();
const dropdown = ref(null);
const dropdownVisible = ref(false);
const instance = getCurrentInstance();
const dropdownRoutes = computed(() => {
    return props.routes.filter((route) => {
        return (
            route.meta.navigation !== undefined &&
            route.meta.navigation === props.navKey
        );
    });
});

const emit = defineEmits(["close-dropdown"]);

watch(
    () => props.visible,
    (visible) => {
        dropdownVisible.value = visible;
    },
);

onMounted(() => {
    // Attach the event listener when the component is mounted
    document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
    // Remove the event listener before the component is destroyed
    document.removeEventListener("click", handleClickOutside);
});

function handleItemClick() {
    dropdownVisible.value = false; // Close the dropdown after an item is clicked
    emit("close-dropdown");
}

//TODO: is there a fix which is better to check the menu refs in the parent?
function handleClickOutside() {
    // Close the dropdown if clicked outside
    if (!instance?.parent?.refs.menu.contains(event.target)) {
        dropdownVisible.value = false;
        emit("close-dropdown");
    }
}
</script>
