import { defineStore, acceptHMRUpdate } from "pinia";
import { type Ref, ref } from "vue";
import { Contact } from "@/@types/contact";
import { Segment } from "@/@types/segment";
import { Invoice, InvoiceGroup } from "@/@types/invoice";
import { Customer, Permission } from "@/@types/customer";
import { RegisteredMutation, UnregisteredMutation } from "@/@types/mutations";
import { GovernmentLevy } from "@/@types/tariff";
import { Connection } from "@/@types/connection";
import { Contract } from "@/class/contract";
import { useUserStore } from "@/store/user.store";
import { localStorageRef } from "@/utilities/localStorage";

export const useCustomerStore = defineStore("customer", () => {
    /**
     * Ref variable for customer details.
     *
     * @type {Ref<Customer>}
     */
    const customer: Ref<Customer> = ref({
        accountHolder: "",
        bankAccountNumber: "",
        bankIdentifierCode: "ABNANL2A",
        chamberOfCommerce: "",
        city: "",
        country: "",
        customerName: "",
        customerNumber: 0,
        houseNumber: 0,
        houseNumberAddition: null,
        postalCode: "",
        street: "",
        team: {
            code: "MKB",
            description: "MKB",
        },
    });

    const connections: Ref<Connection[]> = ref([]);
    const connectionsForMeterreadings: Ref<Connection[]> = ref([]);
    const contracts: Ref<Contract[]> = ref([]);
    const contactPersons: Ref<Contact[]> = ref([]);
    const invoiceGroups: Ref<InvoiceGroup[]> = ref([]);
    const invoices: Ref<Invoice[]> = ref([]);
    const numConnections: Ref<number> = ref(1);
    const numInvoices: Ref<number> = ref(1);
    const paymentMethod: Ref<"INCASSO" | "KLANT"> = ref("INCASSO");
    const segments: Ref<Segment[]> = ref([]);
    const governmentLevies: Ref<GovernmentLevy[]> = ref([]);
    const permissions: Ref<Permission[]> = localStorageRef([], "permissions");

    const signedOnMutations: Ref<RegisteredMutation[]> = ref([]);
    const signedOffMutations: Ref<UnregisteredMutation[]> = ref([]);

    const setCustomer = (customerData: Partial<Customer>) =>
        (customer.value = {
            ...customer.value,
            ...customerData,
        });

    const setContracts = (val: Contract[]) => (contracts.value = val);

    const setConnections = (val: Connection[]) => (connections.value = val);
    const setConnectionsForMeterreadings = (val: Connection[]) =>
        (connectionsForMeterreadings.value = val);

    const setContactPersons = (val: Contact[]) => (contactPersons.value = val);

    const setInvoiceGroups = (val: InvoiceGroup[]) =>
        (invoiceGroups.value = val);

    const setInvoices = (val: Invoice[]) => (invoices.value = val);

    const setNumConnections = (val: number) => (numConnections.value = val);

    const setNumInvoices = (val: number) => (numInvoices.value = val);

    const setPaymentMethod = (val: "INCASSO" | "KLANT") =>
        (paymentMethod.value = val);

    const setGovernmentLevies = (val: GovernmentLevy[]) =>
        (governmentLevies.value = val);

    const setSegments = (val: Segment[]) => (segments.value = val);

    const setPermissions = (val: Permission[]) => {
        permissions.value = val;
        localStorage.setItem("permissions", JSON.stringify(val));
    };

    const setSignedOnMutations = (val: RegisteredMutation[]) =>
        (signedOnMutations.value = val);

    const setSignedOffMutations = (val: UnregisteredMutation[]) =>
        (signedOffMutations.value = val);

    const userStore = useUserStore();

    /**
     * Resets all the values in the application state.
     *
     * @function $reset
     *
     * @returns {void} - No value is returned.
     */
    function $reset(): void {
        // Also reset the userStore as it contains user related data
        userStore.$reset();

        connections.value = [];
        connectionsForMeterreadings.value = [];
        contactPersons.value = [];
        contracts.value = [];
        governmentLevies.value = [];
        invoiceGroups.value = [];
        invoices.value = [];
        numConnections.value = 1;
        numInvoices.value = 1;
        paymentMethod.value = "INCASSO";
        permissions.value = [];
        segments.value = [];
        signedOffMutations.value = [];
        signedOnMutations.value = [];
    }

    return {
        customer,
        contracts,
        connections,
        connectionsForMeterreadings,
        contactPersons,
        invoiceGroups,
        invoices,
        governementLevies: governmentLevies,
        numConnections,
        numInvoices,
        paymentMethod,
        permissions,
        segments,
        signedOnMutations,
        signedOffMutations,
        $reset,
        setCustomer,
        setContracts,
        setConnections,
        setConnectionsForMeterreadings,
        setContactPersons,
        setInvoiceGroups,
        setInvoices,
        setNumConnections,
        setNumInvoices,
        setPaymentMethod,
        setGovernmentLevies,
        setSegments,
        setPermissions,
        //        setToken,
        setSignedOnMutations,
        setSignedOffMutations,
        //        isTokenValid,
    };
});

// https://pinia.vuejs.org/cookbook/hot-module-replacement.html#HMR-Hot-Module-Replacement-
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCustomerStore, import.meta.hot));
}
