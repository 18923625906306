<template>
    <li
        ref="menu"
        :class="{
            'inline-flex': split && routes.length,
            relative: routes.length,
        }"
        :role="split && routes.length ? 'group' : ''"
    >
        <span
            :class="{
                'px-4': !split && !end,
                'pl-4 pr-1': split,
                'pr-0': end,
                'cursor-pointer hover:text-green-500 dark:hover:text-white':
                    routes.length,
            }"
            class="inline-block py-2 font-source-sans text-gray-900 dark:text-gray-200 dark:text-white"
            @click="toggleDropdown"
        >
            <slot />
            <font-awesome-icon
                v-if="!split && routes.length"
                class="icon [padding-left:var(--gds-space-xs)]"
                icon="angle-down"
            />
        </span>
        <button
            v-if="split && routes.length"
            type="button"
            class="border-gray-200 px-1 py-2 text-sm font-medium text-gray-900 hover:text-green-500 dark:text-white"
            @click="toggleDropdown"
        >
            <font-awesome-icon class="icon" icon="angle-down" />
        </button>
        <gz-nav-dropdown
            v-if="routes.length"
            right
            :nav-key="navKey"
            fullwidth
            :visible="dropdown"
            :routes="routes"
            @close-dropdown="closeDropdown"
        />
    </li>
</template>

<script lang="ts" setup>
import { ref, Ref } from "vue";
import GzNavDropdown from "./GzNavDropdown.vue";

/**
 * Define props with type annotations
 */
const props = defineProps({
    routes: {
        required: false,
        type: Array,
        default: () => [],
    },
    split: {
        required: false,
        type: Boolean,
        default: false,
    },
    end: {
        required: false,
        type: Boolean,
        default: false,
    },
    navKey: {
        required: false,
        type: String,
        default: "dropdown",
    },
});

const dropdown: Ref<boolean> = ref(false);

const toggleDropdown = () => {
    if (props.routes.length) {
        dropdown.value = !dropdown.value;
    }
};

function closeDropdown() {
    dropdown.value = false;
}
</script>
