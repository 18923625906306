import {
    type NavigationGuardNext,
    type NavigationGuardWithThis,
    type RouteLocationNormalized,
} from "vue-router";
import { log } from "@/utilities/logger";
import { isDefined } from "@/utilities/validation";
import { addBodyClass, removeBodyClass } from "@/utilities/dom";
import { useUserStore } from "@/store/user.store";
import { logOut, LoginType } from "@/composables/user";
import { useProducerStore } from "@/store/producer.store";

export const beforeNavigationGuardSetLoginType: NavigationGuardWithThis<
    undefined
> = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
) => {
    const userStore = useUserStore();
    const pathName = String(to.name);

    if (pathName.startsWith("navigatie.zakelijk")) {
        userStore.setType(LoginType.Business);
    } else if (pathName.startsWith("navigatie.producenten")) {
        userStore.setType(LoginType.Producer);
    } else {
        userStore.setType(LoginType.Business);
    }
    next();
};

/**
 * Check authentication before navigating to a route
 * @param to - The destination route
 * @param from - The current route
 * @param next - A function to proceed to the next navigation guard
 * @returns {void}
 */
export const beforeNavigationGuardCheckAuthentication: NavigationGuardWithThis<
    undefined
> = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
) => {
    const guardName = "beforeNavigationGuardCheckAuthentication";
    const userStore = useUserStore();

    const isAuthenticationRequiredForToRoute: boolean =
        typeof to.meta.noAuth === "undefined" || !to.meta.noAuth;

    const logging = [
        ["to", to],
        ["from", from],
        ["isAuthenticationRequired", isAuthenticationRequiredForToRoute],
        ["isTokenValid", userStore.isTokenValid],
        ["Token", userStore.token],
        ["Token valid till", new Date(Number(userStore.tokenExpiresAt))],
    ];

    // If token is invalidated and the route is protected
    // redirect the user to the logOut page
    if (!userStore.isTokenValid && isAuthenticationRequiredForToRoute) {
        logging.push(["Token invalid. Navigating to login page"]);
        log(logging, guardName);
        logOut(from);
    }

    if (from.name) {
        removeBodyClass(
            "page-" + (from.name as string).replace("navigatie.", ""),
        );
    }

    if (to.name) {
        addBodyClass("page-" + (to.name as string).replace("navigatie.", ""));
    }

    if (from.name !== to.name) {
        logging.push(["Page navigating to path using next()", to.fullPath]);
        log(logging, guardName);
        next();
    }
};

export const beforeNavigationGuardCheckPermission: NavigationGuardWithThis<
    undefined
> = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
) => {
    const logging = [["to", to]];
    const guardName = "beforeNavigationGuardCheckPermission";

    if (isDefined(to.meta.permission)) {
        if (to.meta.permission!()) {
            logging.push(["Permission granted"]);
            log(logging, guardName);
            next();
        } else {
            logging.push(["Permission not granted. Interrupting navigation"]);
            log(logging, guardName);
            next(false);
        }
    } else {
        logging.push(["Permission not needed"]);
        log(logging, guardName);
        next();
    }
};

/**
 * Navigation guard that checks if a producer customer is needed before
 * allowing navigation to the specified route.
 *
 * @param to - The target route being navigated to.
 * @param from - The current route from which the navigation is triggered.
 * @param next - A function that must be called to resolve the navigation.
 */
export const beforeNavigationGuardCheckProducerCustomer: NavigationGuardWithThis<
    undefined
> = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
) => {
    const logging = [["to", to]];
    const guardName = "beforeNavigationGuardCheckProducerCustomer";
    const producerStore = useProducerStore();

    if (to.meta.producerCustomerNeeded!) {
        if (producerStore.customer?.id != null) {
            logging.push(
                ["producerCustomer selected"],
                ["producerCustomerId: ", producerStore.customer.id.toString()],
                ["producerCustomerName: ", producerStore.customer.name],
            );
            log(logging, guardName);
            next();
        } else {
            logging.push([
                "No producerCustomer selected. redirect to customerSelection",
            ]);
            log(logging, guardName);
            next({ name: "navigatie.producenten.customerSelection" });
        }
    } else {
        next();
    }
};
