export default {
    CSVNietBeschikbaarTooltip:
        "U kunt geen verbruik downloaden van deze aansluiting, omdat uw uitleesfrequentie op dag of groter staat.<br>Wilt u wel verbruiken kunnen downloaden, moet u uw frequentie aanpassen naar uur voor gas of kwartier voor electriciteit.<br><br>Let op: De verbruiken zijn beschikbaar vanaf het tijdstip dat de wijziging is doorgevoerd (werkt niet met terugwerkende kracht).",
    aansluitingDisabledTooltip:
        "Op dit moment kunnen er geen aansluitingen gekozen worden met verschillende jaren als startdatum.",
    loading: "Verbruiksgegevens worden geladen.",
    noData: "Geen verbruiksgegevens gevonden",
    serieType: {
        Gas: "Gas",
        Levering: "Stroom",
        Teruglevering: "Teruglevering",
        Nettoverbruik: "Nettoverbruik",
        Vast: "Vaste kosten",
        Terugleverkosten: "Terugleverkosten",
        Windvanger: "WindVangers",
        Onbekend: "Onbekend"
    },
    unit: {
        Euro: "&euro;",
        Kwh: "kWh",
        M3: "m&#179;"
    },
    usage: "Verbruiksgegevens"
};
