<template>
    <font-awesome-icon v-if="setIcon" :class="iconClasses" :icon="setIcon" />
</template>

<script setup lang="ts">
import iconProps from "./icon.props";
import { computed } from "vue";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

// The import of `FontAwesomeIcon` was added explicitly because GzVerbruikTooltip.vue and GzVerbruikLegenda.vue are rendered outside the Vue instance.
// They are rendered separately as the Highcharts Vue package does not support slots or similar.
// The absence of this component triggered an error, preventing GzVerbruikTooltip.vue from being rendered.
// See utilities/components.ts for the function utilized for this purpose.
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps(iconProps);

const iconInactive: IconProp | null = null;

const iconClasses = computed(() => {
    const classes: string[] = [];

    if (props.colored && props.cssClass && !props.inactive) {
        classes.push(props.cssClass);
    }
    if (props.stackedLeft) {
        classes.push("absolute");
        classes.push("right-3");
    }
    if (props.stackedRight) {
        classes.push("absolute");
        classes.push("left-3");
    }

    props.xl ? classes.push("w-20") : "";
    props.lg ? classes.push("w-14") : "";
    props.md ? classes.push("w-8") : "";
    props.sm ? classes.push("w-4") : "";
    props.xs ? classes.push("w-3") : "";

    props.xl || props.lg || props.md || props.sm || props.xs
        ? classes.push("flex-no-shrink fill-current h-fit")
        : "";

    return classes.join(" ");
});

const setIcon = computed(() =>
    props.inactive && iconInactive ? iconInactive : props.icon,
);
</script>
