<template>
    <component
        :is="element"
        :class="classes"
        :disabled="disabled"
        :to="to"
        :type="type"
        @click="click"
        @mousedown="clickInterval"
        @mouseup="clearClickInterval"
        @keyup.enter="enter"
    >
        <span v-if="toggle">
            <slot v-if="toggled" name="toggled" />
            <slot v-else name="untoggled" />
        </span>

        <slot v-if="!busy" />

        <font-awesome-icon
            v-else
            class="w-14 animate-spin text-white"
            icon="fa-spinner"
        />
        <font-awesome-icon
            v-if="arrow"
            class="ml-3"
            icon="fa-solid fa-chevron-right"
        />
    </component>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, Ref, watchEffect } from "vue";

const props = defineProps({
    tag: {
        required: false,
        type: String,
        default: "button",
    },
    type: {
        required: false,
        type: String,
        default: "button",
    },
    arrow: {
        required: false,
        type: Boolean,
        default: false,
    },
    rounded: {
        required: false,
        type: Boolean,
        default: false,
    },
    enterButton: {
        required: false,
        type: Boolean,
        default: false,
    },
    square: {
        required: false,
        type: Boolean,
        default: false,
    },
    // Green
    primary: {
        required: false,
        type: Boolean,
        default: false,
    },
    // Orange
    secondary: {
        required: false,
        type: Boolean,
        default: false,
    },
    // White
    tertiary: {
        required: false,
        type: Boolean,
        default: false,
    },
    // Red
    quaternary: {
        required: false,
        type: Boolean,
        default: false,
    },
    // Dark Green
    quinary: {
        required: false,
        type: Boolean,
        default: false,
    },
    to: {
        required: false,
        type: Object,
        default: () => {},
    },
    large: {
        required: false,
        type: Boolean,
        default: false,
    },
    small: {
        required: false,
        type: Boolean,
        default: false,
    },
    round: {
        required: false,
        type: Boolean,
        default: false,
    },
    disabled: {
        required: false,
        type: Boolean,
        default: false,
    },
    busy: {
        required: false,
        type: Boolean,
        default: false,
    },
    shadow: {
        required: false,
        type: Boolean,
        default: false,
    },
    width: {
        required: false,
        type: String,
        default: null,
    },
    height: {
        required: false,
        type: String,
        default: null,
    },
    /**
     * When true, fires the click event when pressing the button down
     */
    spin: {
        required: false,
        type: Boolean,
        default: false,
    },
    link: {
        required: false,
        type: Boolean,
        default: false,
    },
    toggle: {
        required: false,
        type: Boolean,
        default: false,
    },
});

/* Define the ref variables. */
const mouseDown = ref(false);
const interval: Ref<number | null> = ref(null);
const toggled = ref(false);

/*
 * Define the computed properties.
 * The element property returns a router-link or a button based on the to property.
 * The isLink property returns a boolean indicating if the component is a link or not.
 */
const element = computed(() => (props.to ? "router-link" : props.tag));
const isLink = computed(
    () =>
        props.link || (props.to && Object.keys(props.to as object).length > 0),
);

const classes = computed(() => {
    const classesArray: string[] = [];

    if (props.disabled || props.busy) {
        classesArray.push(
            "disabled:bg-gray-50 border-0 text-gray-200 cursor-not-allowed",
        );
    }

    if (isLink.value) {
        classesArray.push(
            "bg-transparent",
            "font-normal",
            "h-auto",
            "items-baseline",
            "mb-0",
            "no-underline",
            "pl-0",
            "pr-0",
            "text-green-900",
            "text-left",
        );
    } else if (props.primary) {
        classesArray.push(
            "bg-green-500",
            "border-transparent",
            "text-gray-800",
            "font-semibold",
        );
    } else if (props.secondary) {
        classesArray.push(
            "font-source-sans",
            "bg-yellow-500",
            "border-transparent",
            "text-gray-800",
            "font-semibold",
            "hover:bg-yellow-400",
        );
    } else if (props.tertiary) {
        classesArray.push(
            "bg-white",
            "shadow-md",
            "hover:border-green-500",
            "active:shadow-none",
            "disabled:shadow-none",
            "border-gray-400",
            "text-gray-800",
            "font-semibold",
        );
    } else if (props.quaternary) {
        classesArray.push(
            "bg-red-600",
            "border-red-600",
            "text-white",
            "font-semibold",
        );
    } else if (props.quinary) {
        classesArray.push(
            "bg-green-800",
            "border-transparent",
            "text-white",
            "font-semibold",
        );
    }

    if (props.square) {
        classesArray.push("aspect-square");
    } else {
        classesArray.push(props.height);
    }

    classesArray.push(props.width);

    if (
        props.primary ||
        props.secondary ||
        props.tertiary ||
        props.quaternary ||
        props.quinary
    ) {
        classesArray.push(
            "align-top",
            "border",
            "cursor-pointer",
            "font-source-sans",
            "items-center",
            "justify-center",
            "leading-normal",
            "no-underline",
            "relative",
            "select-none",
            "text-base",
            "text-center",
            "whitespace-normal",
        );
    }

    if (props.large) {
        classesArray.push("p-5");
    } else if (props.small) {
        classesArray.push("p-1");
    } else {
        classesArray.push("p-2");
    }

    if (props.round) {
        classesArray.push("rounded-full");
    } else if (props.rounded) {
        classesArray.push("rounded");
    }

    if (props.shadow) {
        classesArray.push("shadow-xl");
    }

    return classesArray.join(" ");
});

/* Define the methods for the component. */

/**
 * Emit the 'click' event if the button is not disabled.
 */
const click = () => {
    if (!props.disabled) {
        if (props.toggle) {
            toggled.value = !toggled.value;
        }
        if (props.spin && mouseDown.value && !interval.value) {
            interval.value = setInterval(() => {
                emit("click");
            }, 200) as unknown as number;
        } else {
            emit("click");
        }
    }
};

/**
 * Capture a 'mousedown' event and call the click method if the 'spin' attribute is true.
 */
const clickInterval = () => {
    if (props.spin) {
        mouseDown.value = true;
        click();
    }
};

/**
 * Clear the click interval when 'mouseup' or 'mouseleave' is emitted.
 */
const clearClickInterval = () => {
    if (props.spin) {
        mouseDown.value = false;
        if (interval.value) {
            clearInterval(interval.value);
            interval.value = null;
        }
    }
};

/**
 * Emit the 'click' event when the key press is 'Enter' or 'NumpadEnter'.
 */
const enter = (event: KeyboardEvent) => {
    if (props.enterButton && ["NumpadEnter", "Enter"].includes(event.code)) {
        emit("click");
    }
};

watchEffect(() => {
    if (props.disabled && props.spin) {
        clearClickInterval();
    }
});

onMounted(() => {
    if (props.spin && !isLink.value) {
        window.addEventListener("mouseup", clearClickInterval);
    }

    if (props.enterButton) {
        window.addEventListener("keypress", enter);
    }
});

onUnmounted(() => {
    if (props.spin && !isLink.value) {
        window.removeEventListener("mouseup", clearClickInterval);
    }

    if (props.enterButton) {
        window.removeEventListener("keypress", enter);
    }
});

const emit = defineEmits(["click"]);
</script>
