<template>
    <div class="bg-[var(--gds-color-bg-lightest)]">
        <GdsGrid variant="main" is-centered>
            <GdsGridItem>
                <nav
                    class="z-50 max-w-full py-4 dark:bg-gray-800"
                    data-testid="navBar"
                    role="menubar"
                >
                    <div
                        class="flex flex-wrap items-center justify-center"
                        :class="{
                            container: props.container,
                            'mx-auto': props.centered && props.container,
                        }"
                    >
                        <gz-button
                            v-if="props.showMenu"
                            class="order-first ml-3 inline-flex p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden"
                            :aria-expanded="mobileNav"
                            @click="toggleNav"
                        >
                            <span class="sr-only">Open hoofdmenu</span>
                            <svg
                                v-if="!mobileNav"
                                class="h-6 w-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clip-rule="evenodd"
                                />
                            </svg>

                            <svg
                                v-else
                                class="h-6 w-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </gz-button>

                        <router-link
                            :to="logoLink"
                            class="mx-auto my-0.5 dark:bg-white sm:ml-0 sm:mr-3"
                            data-testid="greenchoiceLogo"
                            @click="toggleNav"
                        >
                            <img
                                alt=""
                                src="/resources/static/images/logo/greenchoice.svg"
                                class="h-12"
                            />
                        </router-link>

                        <gz-button
                            class="mr-4 md:hidden"
                            @click="toggleProfileButton"
                        >
                            <gz-icon
                                class="text-green-700"
                                icon="user"
                            ></gz-icon>
                        </gz-button>

                        <gz-nav
                            class="order-2 ml-0 justify-self-start md:hidden"
                            :visible="mobileProfileNav"
                            order="1"
                            highlight
                        >
                            <gz-nav-item
                                v-for="route in profile"
                                :key="route.path"
                                class="md:items-center"
                                split
                                :route="route"
                                @click="toggleProfileButton"
                            >
                                {{ t(route.name as string) }}
                            </gz-nav-item>
                        </gz-nav>

                        <gz-nav
                            v-if="props.showMenu"
                            class="order-2 ml-0 justify-self-start"
                            :visible="mobileNav"
                            order="1"
                            highlight
                        >
                            <gz-nav-item
                                v-for="route in main"
                                :key="route.path"
                                class="md:items-center"
                                split
                                :route="route"
                                @click="toggleNav"
                            >
                                {{ t(route.name as string) }}
                            </gz-nav-item>
                        </gz-nav>

                        <gz-nav order="4" class="ml-auto">
                            <gz-nav-text
                                nav-key="profile"
                                :routes="profile"
                                data-testid="gebruikersnaam"
                                end
                            >
                                {{ profileName }}
                            </gz-nav-text>
                        </gz-nav>
                    </div>
                </nav>
            </GdsGridItem>
        </GdsGrid>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import GzNav from "./GzNav.vue";
import GzNavItem from "./GzNavItem.vue";
import GzNavText from "./GzNavText.vue";
import GzButton from "../button/GzButton.vue";
import { useServicePartnerStore } from "@/store/partner.store";
import { useCustomerStore } from "@/store/customer.store";
import GzIcon from "@/components/icon/GzIcon.vue";
import { useProducerStore } from "@/store/producer.store";
import { useUserStore } from "@/store/user.store";
import { GdsGrid, GdsGridItem } from "@greenchoice/ds";

const partnerStore = useServicePartnerStore();
const customerStore = useCustomerStore();
const producerStore = useProducerStore();
const userStore = useUserStore();

/**
 * Represents the link for the logo based on whether the user is a partner or not.
 *
 * @returns {string} The computed logo link.
 */
const logoLink = computed(() => {
    if (partnerStore.isPartner) {
        return "/klantenoverzicht";
    } else if (userStore.isBusiness) {
        return "/zakelijk";
    } else {
        return "/producenten";
    }
});

const { t } = useI18n();
const router = useRouter();

const props = defineProps({
    container: {
        required: false,
        type: Boolean,
        default: false,
    },
    centered: {
        required: false,
        type: Boolean,
        default: false,
    },
    showMenu: {
        required: false,
        type: Boolean,
        default: true,
    },
});

/**
 * Toggles state when button is clicked in mobile view
 */
const mobileNav = ref(false);
const mobileProfileNav = ref(false);
const toggleNav = () => {
    mobileProfileNav.value = false;
    mobileNav.value = !mobileNav.value;
};
const toggleProfileButton = () => {
    mobileNav.value = false;
    mobileProfileNav.value = !mobileProfileNav.value;
};

/**
 * Filters and returns routes based on the provided navigation.
 * @param {string} navigation
 * @returns {array} routes
 */
const getRoutes = (navigation: string) => {
    return router.options.routes.filter((route) => {
        return (
            route.meta!.visible() &&
            route.meta!.navigation &&
            route.meta!.navigation === navigation
        );
    });
};

const profileName: ComputedRef<string> = computed(() => {
    if (userStore.isBusiness) {
        return customerStore.customer.customerName;
    } else if (producerStore.customer != null) {
        return userStore.name + " · " + producerStore.customer.name;
    }
    return userStore.name;
});

const main = computed(() => getRoutes("main"));
const profile = computed(() => [...getRoutes("profile")]);
</script>
