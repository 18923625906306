/**
 * Logs a message to the console based on the specified log level.
 * Only logs messages if the environment is not in production mode.
 *
 * @param messages
 * @param groupLabel
 * @param {"debug" | "info" | "warn" | "error"} [level="debug"] - The log level. Defaults to "debug".
 * @returns {void}
 */
export function log(
    messages: never[] | never = [],
    groupLabel: string | null = null,
    level: "debug" | "info" | "warn" | "error" = "debug",
): void {
    if (!import.meta.env.PROD && messages.length > 0) {
        // eslint-disable-next-line no-console
        console.groupCollapsed(`[${level}] ${groupLabel}`);
        switch (level) {
            case "debug":
                messages.forEach((message) =>
                    // eslint-disable-next-line no-console
                    console.debug(message[0], message[1] ?? null),
                );
                break;

            case "info":
                messages.forEach((message) =>
                    // eslint-disable-next-line no-console
                    console.info(message[0], message[1] ?? null),
                );
                break;

            case "warn":
                messages.forEach((message) =>
                    // eslint-disable-next-line no-console
                    console.warn(message[0], message[1] ?? null),
                );
                break;

            case "error":
                messages.forEach((message) =>
                    // eslint-disable-next-line no-console
                    console.error(message[0], message[1] ?? null),
                );
                break;
        }

        // eslint-disable-next-line no-console
        console.groupEnd();
    }
}
