import { App, createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import { createI18n } from "vue-i18n";
import VueGtmOptions from "@/plugins/gtm/gtm.options";
import VueAxios from "vue-axios";
import "moment/dist/locale/nl.js";
import HighchartsVue from "highcharts-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAnglesLeft,
    faAnglesRight,
    faBolt,
    faCaretDown,
    faCaretUp,
    faCheck,
    faCheckDouble,
    faChevronLeft,
    faChevronRight,
    faCircleExclamation,
    faCircleCheck,
    faCircleInfo,
    faCircleQuestion,
    faCircleXmark,
    faDownload,
    faEuroSign,
    faEye,
    faEyeSlash,
    faFilePdf,
    faFireFlameCurved,
    faMagnifyingGlass,
    faMinus,
    faPaperclip,
    faPenToSquare,
    faPlugCircleCheck,
    faPlugCircleXmark,
    faPlus,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faSun,
    faUser,
    faWind,
    faWifi,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import GzApp from "@/layouts/GzApp.vue";
import nl from "../lang/nl/nl";
import { createPinia, type Pinia } from "pinia";
import { routes } from "@/router/app.router";
import {
    beforeNavigationGuardSetLoginType,
    beforeNavigationGuardCheckAuthentication,
    beforeNavigationGuardCheckPermission,
    beforeNavigationGuardCheckProducerCustomer,
} from "@/router/router.beforeEach";
import MaskDirective from "@/directives/mask/mask.directive";
import "@greenchoice/ds/dist/src/assets/gds-light.css";
import { businessLegacyAxios } from "@/composables/customer";
import { producerAxios } from "@/composables/producer";
import { createRouter, createWebHistory } from "vue-router";

import.meta.glob(["~/images/**", "~/fonts/**"]);

library.add(
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAnglesLeft,
    faAnglesRight,
    faBolt,
    faCaretDown,
    faCaretUp,
    faCheck,
    faCheckDouble,
    faChevronLeft,
    faChevronRight,
    faCircleCheck,
    faCircleExclamation,
    faCircleInfo,
    faCircleQuestion,
    faCircleXmark,
    faDownload,
    faEuroSign,
    faEye,
    faEyeSlash,
    faFilePdf,
    faFireFlameCurved,
    faMagnifyingGlass,
    faMinus,
    faPaperclip,
    faPenToSquare,
    faPlugCircleCheck,
    faPlugCircleXmark,
    faPlus,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faSun,
    faUser,
    faWind,
    faWifi,
    faXmark,
);

// TODO: Axios interceptors
//axios.interceptors.response.use(Response401, Error401);

const pinia: Pinia = createPinia();
const app: App<Element> = createApp(GzApp);

app.use(pinia);

// Create the Router in the App so the router has access to the store useUserStore
// Which was needed to make the base dynamic
const appRouter = createRouter({
    history: createWebHistory(),
    routes: routes,
});

appRouter.beforeEach(beforeNavigationGuardSetLoginType);
appRouter.beforeEach(beforeNavigationGuardCheckAuthentication);
appRouter.beforeEach(beforeNavigationGuardCheckPermission);
appRouter.beforeEach(beforeNavigationGuardCheckProducerCustomer);

app.component("FontAwesomeIcon", FontAwesomeIcon);

app.use(appRouter);
app.use(HighchartsVue);

app.use(VueAxios, { axios: businessLegacyAxios, producerAxios: producerAxios });
app.provide("axios", app.config.globalProperties.axios);
app.provide("producerAxios", app.config.globalProperties.producerAxios);

app.use(createGtm(VueGtmOptions(appRouter)));
app.use(
    createI18n({
        legacy: false,
        locale: "nl",
        messages: nl,
        allowComposition: true,
    }),
);

app.directive("mask", MaskDirective);

app.mount("#app");
