export default {
    colored: {
        required: false,
        default: false,
        type: Boolean,
    },
    inactive: {
        required: false,
        default: false,
        type: Boolean,
    },
    xl: {
        required: false,
        default: false,
        type: Boolean,
    },
    lg: {
        required: false,
        default: false,
        type: Boolean,
    },
    md: {
        required: false,
        default: false,
        type: Boolean,
    },
    sm: {
        required: false,
        default: false,
        type: Boolean,
    },
    xs: {
        required: false,
        default: false,
        type: Boolean,
    },
    stackedLeft: {
        required: false,
        default: false,
        type: Boolean,
    },
    stackedRight: {
        required: false,
        default: false,
        type: Boolean,
    },
    cssClass: { required: false, default: null, type: String },
    icon: { required: false, default: null, type: [String, Object] },
};
