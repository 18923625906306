export default {
    example: "Voorbeeld",
    loader: "Termijnbedragen per segment worden opgehaald",
    nodata: "Geen segmenten gevonden",
    totalAmount: "Totaal termijnbedragen",
    monthly: "per maand",
    monthlyShort: "p/m",
    installmentAmountsPerSegment: "Termijnbedragen per segment",
    electricity: "stroom",
    gas: "gas",
    from: "Vanaf",
    successfullyAdjusted: "Termijnbedrag succesvol aangepast",
    noAmountKnown: "Geen termijnbedrag bekend",
    capitalOurAdvice: "Ons advies",
    ourAdvice: "ons advies",
    announcements: "Mededelingen",
    installmentAmount: "Termijnbedrag",
    customerSupport: "klantenservice",
    noDataForSegment:
        "We hebben geen data over het termijnbedrag van segment {segment}. Klopt dit niet? Neem dan contact op met de",
    negativeSegmentAmount:
        "We verwachten dat het termijnbedrag van segment {segment} negatief zal uitvallen. Je kunt het termijnbedrag niet negatief aanpassen. Denk je dat onze inschatting onjuist is, neem dan contact op met onze",
    estimateAmount1: "Wij schatten je termijnbedrag op",
    estimateAmount2: "per maand op basis van onze berekening.",
    expectChange:
        "Verwacht je minder of juist meer energie te gaan verbruiken? Pas je termijnbedrag dan aan.",
    adjustMinMax:
        "Je kunt je termijnbedrag aanpassen tussen {min} en {max}, zodat het goed aansluit bij je verbruik. Grotere afwijkingen zijn niet mogelijk om verrassingen te voorkomen.",
    cantChangeNegativeInstallmentAmount:
        "Het termijnbedrag voor {segment} kan niet aangepast worden. Een termijnbedrag kan niet negatief zijn.",
    amountIsEqual:
        "Het ingevulde termijnbedrag is gelijk aan het huidige termijnbedrag.",
    inputInvalid: "Het ingevulde termijnbedrag voldoet niet.",
};
