export default {
    business: {
        connection: "Aansluiting",
        nodata: "Geen facturen gevonden",
        amount: "Bedrag",
        changepaymethod: "Pas betaalwijze aan",
        date: "Datum",
        status: "Status",
        description: "Omschrijving",
        invoicenumber: "Factuurnummer",
        invoicedate: "Factuurdatum",
        paymethod: "Betaalwijze",
        invoicetype: "Factuurtype",
        invoicegroup: "Factuurgroep",
        open: "Open",
        openpdf: "Open PDF",
        downloadpdf: "Download PDF",
        downloadattachment: "Download factuurbijlage als zip",
        downloadinvoicelines: "Download factuurregels",
        iban: "Rekeningnummer",
        name: "Tenaamstelling",
        filterean: "Zoek naar aansluiting",
    },
    producers: {
        errormessage:
            "Er is iets misgegaan bij het ophalen van de nota's.",
        invoicenumber: "Factuurnummer",
        invoices: "Nota's",
        nodata: "Geen nota's gevonden",
        retrieving: "{msg} worden opgehaald",
    }
};