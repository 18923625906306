<template>
    <div v-if="isFunnel">
        <GdsHeader
            is-in-funnel
            :logo-link="{ url: logoLink }"
            :cancel-href="cancelLink"
        />
        <GdsFunnelContainer
            class="flex min-h-[70vh] items-center justify-center [background-color:var(--gds-color-bg-lighter)]"
        >
            <GdsGridItem class="column">
                <router-view />
            </GdsGridItem>
        </GdsFunnelContainer>
    </div>
    <template v-else-if="isPage">
        <GdsGrid variant="page" class="min-h-[100vh]">
            <GdsGridItem>
                <gz-nav-bar
                    v-if="navBar.show"
                    :show-menu="showMenu"
                    centered
                    container
                />
                <div v-else class="container mx-auto">
                    <img
                        class="ml-4 mt-4"
                        alt=""
                        width="120"
                        height="83"
                        src="/resources/static/images/logo/greenchoice.svg"
                    />
                </div>
                <gz-hero v-if="hero.show" centered container translate />
                <GdsGrid variant="main" is-centered class="-mt-24">
                    <GdsGridItem>
                        <component
                            v-bind="topPosition.props"
                            :is="topPosition.component"
                            v-if="topPosition"
                            v-html="t(topContent)"
                        />
                    </GdsGridItem>
                    <GdsGridItem>
                        <router-view />
                    </GdsGridItem>
                    <GdsGridItem>
                        <component
                            v-bind="bottomPosition.props"
                            :is="bottomPosition.component"
                            v-if="bottomPosition"
                            v-html="t(bottomContent)"
                        />
                    </GdsGridItem>
                </GdsGrid>
            </GdsGridItem>
            <GdsGridItem
                v-if="footerPosition && showFooter"
                class="mt-[var(--gds-space-xl)] self-end"
            >
                <gz-footer />
            </GdsGridItem>
        </GdsGrid>
    </template>
    <GdsIconDefinitions />
</template>

<script setup lang="ts">
import GzNavBar from "../components/navigation/GzNavBar.vue";
import GzHero from "../components/header/GzHero.vue";
import GzFooter from "../components/footer/GzFooter.vue";
import { onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { getAllCustomerData } from "@/composables/customer";
import { useServicePartnerStore } from "@/store/partner.store";
import { isDefined } from "@/utilities/validation";
import {
    GdsFunnelContainer,
    GdsGrid,
    GdsGridItem,
    GdsHeader,
    GdsIconDefinitions,
} from "@greenchoice/ds";
import { useUserStore } from "@/store/user.store";
import { useFunnelStore } from "@/store/funnel.store";

const router = useRouter();
const currentRoute = useRoute();
const { t } = useI18n();
const funnelStore = useFunnelStore();

const topPosition = computed(() => {
    return isDefined(currentRoute.meta.page) &&
        isDefined(currentRoute.meta.page!.top)
        ? currentRoute.meta.page!.top
        : false;
});

const topContent = computed(() => {
    return topPosition.value ? topPosition.value.content : "";
});

const bottomPosition = computed(() => {
    return isDefined(currentRoute.meta.page) &&
        isDefined(currentRoute.meta.page!.bottom)
        ? currentRoute.meta.page!.bottom
        : false;
});

const bottomContent = computed(() => {
    return bottomPosition.value ? bottomPosition.value.content : "";
});

const footerPosition = computed(() => {
    return isDefined(currentRoute.meta.page) &&
        isDefined(currentRoute.meta.page!.footer)
        ? currentRoute.meta.page!.footer
        : false;
});

/**
 * Calculates the navigation bar options based on the current route's meta information.
 *
 * @returns {Object} - The navigation bar options object.
 */
const navBar = computed(() => {
    // The show property toggles the visibility of the navigation bar. It's the default value
    const values = {
        show: true,
    };

    return currentRoute.meta.page
        ? Object.assign(values, currentRoute.meta.page.navigation)
        : values;
});

const showMenu = computed(() =>
    isDefined(currentRoute.meta.page) &&
    isDefined(currentRoute.meta.page!.navigation!) &&
    isDefined(currentRoute.meta.page!.navigation!.menu)
        ? currentRoute.meta.page!.navigation!.menu
        : true,
);

const showFooter = computed(() =>
    isDefined(currentRoute.meta.page) &&
    isDefined(currentRoute.meta.page!.footer!) &&
    isDefined(currentRoute.meta.page!.footer!.show)
        ? currentRoute.meta.page!.footer!.show
        : false,
);

const isFunnel = computed<boolean>(() =>
    isDefined(currentRoute.meta.funnelPage),
);

const isPage = computed<boolean>(() => isDefined(currentRoute.meta.page));

const logoLink = computed<string>(() =>
    isDefined(currentRoute.meta.funnelPage) &&
    isDefined(currentRoute.meta.funnelPage!.logoLink)
        ? currentRoute.meta.funnelPage!.logoLink
        : "",
);

const cancelLink = computed<string>(() => {
    if (isDefined(funnelStore.beforeEnterPath)) {
        return funnelStore.beforeEnterPath;
    }
    return isDefined(currentRoute.meta.funnelPage) &&
        isDefined(currentRoute.meta.funnelPage!.cancelFallbackLink)
        ? currentRoute.meta.funnelPage!.cancelFallbackLink
        : "";
});

/**
 * Computed variable for the hero.
 *
 * @returns {Object} The hero object with the show property and other properties from the currentRoute's meta.page.hero object if present.
 */
const hero = computed(() => {
    // The show property toggles the visibility of the hero. It's the default value
    const values = {
        show: true,
    };

    return currentRoute.meta.page
        ? Object.assign(values, currentRoute.meta.page.hero)
        : values;
});

onMounted(() => {
    const userStore = useUserStore();
    const servicePartnerStore = useServicePartnerStore();
    if (
        userStore.isTokenValid &&
        userStore.isBusiness && // Previously logged in as a customer
        (!servicePartnerStore.isPartner || servicePartnerStore.selectedClient) // Or as a partner who already selected a customer during a previous login
    ) {
        getAllCustomerData();
    } else if (
        userStore.isTokenValid &&
        userStore.isBusiness &&
        servicePartnerStore.isPartner
    ) {
        router.push({ name: "navigatie.zakelijk.klantenoverzicht" });
    }
});
</script>
