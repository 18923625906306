export default {
    retrieving: "Je data voor het dashboard wordt opgehaald.",
    invoicetitle: "Recente facturen",
    invoicedate: "factuurdatum",
    invoicenumber: "factuurnummer",
    invoiceopen: "Openstaand",
    installmentamounttitle: "Termijnbedrag",
    installmentamountmonth: "per maand",
    notmobileready:
        "Wil je uitgebreid inzicht in je verbruik, bekijk deze pagina dan op groter scherm.",
    returnCostsMessage: 'Beste heer/mevrouw {customerName}, er gelden nieuwe voorwaarden voor het terugleveren van zonne-energie. Lees wat dit voor jou betekent op onze <a href="https://www.greenchoice.nl/mkb/klantenservice/terugleverkosten/" target="_blank">website</a>. Heb je een vast contract? Dan blijven je voorwaarden ongewijzigd.',
};
