export default {
    zakelijk: {
        aanmelden: "Aanmelden",
        aansluiting: "Aansluiting",
        aansluitingen: "Aansluitingen",
        afmelden: "Afmelden",
        confirmhousing: "Bevestig aanmelding",
        confirmrelocation: "Bevestig afmelding",
        contact: "Contact",
        contactpersonen: "Contactpersonen",
        contactpersoon: "Contactpersoon",
        contract: "Contract",
        contracten: "Contracten",
        dashboard: "Dashboard",
        facturen: "Facturen",
        factuur: "Factuur",
        gebruikersnaamVergeten: "Gebruikersnaam vergeten",
        inloggen: "Inloggen",
        klantKiezen: "Kies de klant waarvoor je wilt inloggen",
        klantenoverzicht: "Klantenoverzicht",
        mededelingen: "Mededelingen",
        meterstanden: "Meterstanden",
        meterstandenDoorgeven: "Meterstanden doorgeven",
        mutaties: "Mutaties",
        opzegvergoeding: "Opzegvergoeding",
        termijnbedragen: "Termijnbedragen",
        uitloggen: "Uitloggen",
        verbruik: "Verbruik",
        wachtwoordVerander: "Verander uw wachtwoord",
        wachtwoordVergeten: "Vraag wachtwoordresetlink aan",
    },
    producenten: {
        aansluiting: "Aansluiting",
        aansluitingen: "Aansluitingen",
        contact: "Contact",
        // use \u00A0 as no break space as solution for no line break in menu.
        customerSelection: "Beheer\u00A0ander\u00A0bedrijf",
        dashboard: "Dashboard",
        gebruikersnaamVergeten: "Gebruikersnaam vergeten",
        inloggen: "Inloggen",
        mededelingen: "Mededelingen",
        notas: "Nota's",
    	profile: "Profiel",
        uitloggen: "Uitloggen",
        verbruik: "Verbruik",
        wachtwoordVerander: "Verander uw wachtwoord",
        wachtwoordVergeten: "Vraag wachtwoordresetlink aan",
    }
};
