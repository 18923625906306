import { VueGtmUseOptions } from "@gtm-support/vue-gtm";
import { type Router } from "vue-router";

/**
 * Creates options for Vue GTM usage.
 *
 * @param {Router} router - The Vue Router instance.
 * @return {VueGtmUseOptions} Returns the options object for Vue GTM usage.
 */
const options = (router: Router): VueGtmUseOptions => {
    return {
        id: ["GTM-NZZS9T3"],
        enabled: process.env.NODE_ENV === "production",
        debug: process.env.NODE_ENV !== "production",
        loadScript: false,
        vueRouter: router,
    };
};

export default options;
