import { type Ref, shallowRef, watchEffect } from "vue";

/**
 * Creates a ref that is synced with a value stored in local storage.
 *
 * @template T - The type of the value stored in local storage.
 * @param defaultValue - The default value to use if the value is not found in local storage.
 * @param localStorageKey - The key used to retrieve and store the value in local storage.
 * @returns The ref that holds the stored value.
 */
export function localStorageRef<T>(
    defaultValue: T,
    localStorageKey: string,
): Ref<T> {
    // Retrieve the value from localStorage by key
    const localStorageValue: string | null =
        localStorage.getItem(localStorageKey);

    // Set storedValue from localStorage if that value exists
    // Otherwise set the defaultValue
    const storedValue: T = localStorageValue
        ? JSON.parse(localStorageValue)
        : defaultValue;

    // `ref` creates a deeply reactive reference, unwrapping nested objects for reactivity,
    // while shallowRef creates a reactive reference without deep unwrapping,
    // making shallowRef a better choice here to avoid TypeScript type conflicts with Ref<T>.
    const storedRef: Ref<T> = shallowRef(storedValue);

    // Update the local storage whenever the ref value changes
    watchEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(storedRef.value));
    });

    return storedRef;
}
