/**
 * Removes the specified CSS classes from the body element.
 *
 * @param {...string} classes - The CSS classes to be removed from the body element.
 * @return {void}
 */
export function removeBodyClass(...classes: string[]): void {
    const bodyElement = document.querySelector("body");

    bodyElement?.classList.remove(...classes);
}

/**
 * Adds one or more CSS classes to the body element.
 *
 * @param {...string} classes - One or more CSS class names to be added.
 *
 * @return {void}
 */
export function addBodyClass(...classes: string[]): void {
    const bodyElement = document.querySelector("body");

    bodyElement?.classList.add(...classes);
}
