import { LoginType } from "./user";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { useUserStore } from "@/store/user.store";
import { isDefined, isEmpty } from "@/utilities/validation";
import { useProducerStore } from "@/store/producer.store";
import { useLoadersStore } from "@/store/loaders.store";
import { type ProducerConnection } from "@/@types/connection";
import { type ProducerInvoice } from "@/@types/invoice";
import { producerCustomerResponse } from "@/@types/producerCustomer";
import { createDownloadLink } from "@/utilities/file";

let connectionAbort: AbortController;

export type ProducerUser = {
    name: string;
    token: string;
};

export const producerAxios: AxiosInstance = axios.create({
    baseURL: isDefined(import.meta.env)
        ? import.meta.env.VITE_PRODUCERS_API
        : "",
});

producerAxios.isCancel = axios.isCancel;

/**
 * Logs in a user with the provided username and password.
 *
 * @param {string} username - The username of the user.
 * @param {string} password - The password of the user.
 * @throws Error - If no authentication information is given.
 * @return {Promise<Object>} - A promise that resolves to the response data from the login API endpoint.
 */
export async function logInProducer(
    username: string,
    password: string,
): Promise<ProducerUser> {
    if (isEmpty(username) || isEmpty(password)) {
        throw new Error("No authentication information given");
    }

    return await producerAxios
        .post(
            "/api/login",
            {},
            {
                auth: {
                    username: username,
                    password: password,
                },
            },
        )
        .then((response: AxiosResponse<ProducerUser>) => {
            const userStore = useUserStore();
            const producerStore = useProducerStore();

            if (response.data) {
                userStore.setToken(response.data.token);
                userStore.setType(LoginType.Producer);
                userStore.setName(response.data.name);
                // after login, reset choice of producer.
                producerStore.$reset();
            } else {
                userStore.$reset();
            }

            return response.data;
        });
}

/**
 * Logs out the current user by making a GET request to the "/api/klant/logout" endpoint.
 * Removes the "timestamp" item from the local storage and resets the Client object in the VueX store.
 *
 * @returns {Promise<boolean>} A Promise that resolves to a boolean value indicating if the logout was successful.
 */
export async function logOutProducerUser(): Promise<boolean> {
    const producerStore = useProducerStore();
    const userStore = useUserStore();

    if (isEmpty(userStore.token)) {
        producerStore.$reset();
        return true;
    }

    setBearerToken();

    return producerAxios.post("/api/logout").then<boolean>((response) => {
        if (response.data) {
            producerStore.$reset();
        }
        return response.data as boolean;
    });
}

/**
 * Function to handle the request for a password reset link.
 * @param {string | null} username - The username of the user.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
export async function requestProducerPasswordResetLink(
    username: string | null,
): Promise<AxiosResponse> {
    if (!username) {
        throw new Error("Username must be provided");
    }
    return await producerAxios.post(`/api/requestpasswordreset/${username}`);
}

/**
 * Function to handle the request for a password reset link.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
export async function requestProducerPasswordResetLinkForAuthenticatedUser(): Promise<AxiosResponse> {
    setBearerToken();
    return await producerAxios.post("/api/requestpasswordreset");
}

/**
 * Changes the password for a user.
 *
 * @param {string} password - The new password.
 * @param {any} data - Additional data to send with the request.
 * @throws {Error} - If any of the required parameters are missing.
 * @returns {Promise<{ message: string }>} - A promise that resolves to an object containing a message property.
 */
export async function changeProducerPassword(
    password: string,
    data: never,
): Promise<{ message: string }> {
    if (!password || !data) {
        throw new Error(
            "Missing required information for changing the password.",
        );
    }

    return await producerAxios.post("/api/resetpassword", {
        resetcode: data,
        newPassword: password,
    });
}

/**
 * Retrieves the connections for a producer.
 *
 * @returns {Promise<{ connections: ProducerConnection[] } | void > } - A promise that resolves to an object containing
 * the connections or void if an error occurs.
 */
export async function getConnections(): Promise<{
    connections: ProducerConnection[];
} | void> {
    if (connectionAbort) {
        connectionAbort.abort();
    }

    const producerStore = useProducerStore();
    const loaderStore = useLoadersStore();

    connectionAbort = new AbortController();

    if (!producerStore.customer?.id) {
        throw new Error(
            "Missing required information for getting the connection.",
        );
    }

    loaderStore.setConnections(true);

    setBearerToken();

    return await producerAxios
        .get("/api/customers/" + producerStore.customer.id + "/connections", {
            signal: connectionAbort.signal,
        })
        .then<{ connections: ProducerConnection[] }>((response) => {
            return {
                connections: response.data.items,
            };
        })
        .catch((error) => {
            if (!producerAxios.isCancel(producerAxios)) {
                throw error;
            }
        })
        .finally(() => {
            loaderStore.setConnections(false);
        });
}

export async function getCustomers(): Promise<producerCustomerResponse> {
    const producerStore = useProducerStore();
    const loaderStore = useLoadersStore();

    loaderStore.setCustomers(true);

    setBearerToken();

    return await producerAxios
        .get("api/customers")
        .then((response: AxiosResponse<producerCustomerResponse>) => {
            producerStore.setNumberOfCustomers(
                response.data.totalNumberOfItems,
            );
            return response.data;
        })
        .finally(() => {
            loaderStore.setCustomers(false);
        });
}

/**
 * Retrieves the invoices for a producer.
 *
 * @returns {Promise<{ invoices: ProducerInvoice[] }>} - A promise that resolves to an object containing the invoices.
 */
export async function getInvoices(): Promise<{
    invoices: ProducerInvoice[];
}> {
    const producerStore = useProducerStore();
    const loaderStore = useLoadersStore();

    if (!producerStore.customer?.id) {
        throw new Error(
            "Missing required information for getting the invoices.",
        );
    }

    loaderStore.setInvoices(true);

    setBearerToken();

    return await producerAxios
        .get("/api/customers/" + producerStore.customer.id + "/invoices")
        .then<{ invoices: ProducerInvoice[] }>((response) => {
            return response.data;
        })
        .finally(() => {
            loaderStore.setInvoices(false);
        });
}

export async function downloadInvoice(invoiceNumber: string, documentId: number): Promise<void> {
    const producerStore = useProducerStore();

    if (!producerStore.customer?.id) {
        throw new Error(
            "Missing required information for getting the invoices.",
        );
    }

    setBearerToken();

    await producerAxios
        .get(
            "/api/customers/" +
            producerStore.customer.id +
            "/invoices/" +
            invoiceNumber +
            "/download/" +
            documentId,
            {
                responseType: "blob",
            },
        )
        .then((response) => {
            createDownloadLink(response, `Nota - ${invoiceNumber} - ${documentId}.pdf`);
        });
}

export function setBearerToken(): void {
    const userStore = useUserStore();

    producerAxios.defaults.headers.common.Authorization =
        "Bearer " + userStore.token;
}
