export default {
    chargingSolutions: "Laadoplossingen",
    company: "Bedrijf",
    cookies: "Cookies",
    customerService: "Klantenservice",
    description:
        "Bij Greenchoice ben je zeker van 100% groene stroom en draag je als klant automatisch bij aan Natuur voor Morgen. Dat zijn projecten die natuur beschermen, herstellen of aanplanten. Dat doen we in Nederland en ver daarbuiten.",
    highlighted: "Uitgelicht",
    installmentAmount: "Termijnbedrag",
    meterReadings: "Meterstanden",
    moreInfo: "Meer informatie",
    news: "Nieuws",
    otherQuestions: "Overige vragen",
    privacy: "Privacy",
    productAdvice: "Product & advies",
    save: "Besparen",
    solarPanels: "Zonnepanelen",
    terminationFee: "Opzegvergoeding",
    terms: "Voorwaarden",
    title: "Met jou werken we aan Natuur voor Morgen",
    workAtGreenchoice: "Werken bij Greenchoice",
};
