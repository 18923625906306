export const hasSpecialChar = (val: string) =>
    /[!@#$%^&*(),.?:{}|<>]/.test(val);

export const hasNumericChar = (val: string) => /[0-9]+/.test(val);

export const hasCapitalChar = (val: string) => /[A-Z]+/.test(val);

export const hasRequiredLength = (val: string, length: number = 8) =>
    new RegExp(`^.{${length},}$`).test(val);

/**
 * Checks if the given item is defined.
 *
 * @param {*} item - The item to check.
 * @return {boolean} - True if the item is defined, false otherwise.
 */
export function isDefined(item: unknown): boolean {
    return typeof item !== "undefined";
}

/**
 * Checks if the given item is undefined.
 *
 * @param {*} item - The item to check.
 * @return {boolean} - True if the item is undefined, false otherwise.
 */
export function isUndefined(item: unknown): boolean {
    return !isDefined(item);
}

/**
 * Checks whether the given item is an object.
 *
 * @param {any} item - The item to check.
 * @returns {boolean} - Returns true if the item is an object, false otherwise.
 */
export function isObject(item: unknown): boolean {
    return <boolean>item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Checks whether the given item is a number.
 *
 * @param {any} item - The item to check.
 * @returns {boolean} - Returns true if the item is a number, false otherwise.
 */
export function isNumber(item: unknown): boolean {
    return typeof item === "number";
}

/**
 * Checks whether the given item is an empty string, null or undefined is.
 *
 * @param {string} item - The item to check.
 * @returns {boolean} - Returns true if the item is empty, false otherwise.
 */
export function isEmpty(item: string | undefined): boolean {
    return item === "" || item === null || typeof item === "undefined";
}

/**
 * Determines if a given number is zero or higher.
 *
 * @param {number} int - The number to check.
 * @return {boolean} - True if the number is zero or higher, false otherwise.
 */
export function isZeroOrHigher(int: number): boolean {
    return isNumber(int) && int > -1;
}

/**
 * Determines if a given number is one or higher.
 *
 * @param {number} int - The number to check.
 * @return {boolean} - True if the number is one or higher, false otherwise.
 */
export function isOneOrHigher(int: number): boolean {
    return isNumber(int) && int >= 1;
}

/**
 * Checks if the given value is of type boolean.
 *
 * @param {any} bool - The value to be checked.
 * @return {boolean} - Returns true if the value is of type boolean, otherwise returns false.
 */
export function isBoolean(bool: unknown): boolean {
    return typeof bool === "boolean";
}

/**
 * Checks if the given value is a boolean and if it is equal to true.
 *
 * @param {any} bool - The value to check.
 * @return {boolean} - Returns true if the value is a boolean and true, otherwise returns false.
 */
export function isBooleanAndTrue(bool: unknown): boolean {
    return isBoolean(bool) && bool === true;
}

/**
 * Checks if the given value is a boolean and if it is equal to false.
 *
 * @param {any} bool - The value to be checked.
 * @return {boolean} - True if the value is a boolean and is equal to false, false otherwise.
 */
export function isBooleanAndFalse(bool: unknown): boolean {
    return isBoolean(bool) && bool === false;
}