import { Ref, ref } from "vue";
import { acceptHMRUpdate, defineStore } from "pinia";

export const useFunnelStore = defineStore("funnel", () => {
    const beforeEnterPath: Ref<string | undefined> = ref(undefined);

    function setBeforeEnterPath(path: string): void {
        beforeEnterPath.value = path;
    }

    function $reset(): void {
        beforeEnterPath.value = undefined;
    }

    return {
        beforeEnterPath,
        setBeforeEnterPath,
        $reset,
    };
});

// https://pinia.vuejs.org/cookbook/hot-module-replacement.html#HMR-Hot-Module-Replacement-
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useFunnelStore, import.meta.hot));
}
