export default {
    successful:
        "U bent succesvol ingelogd. Moment geduld. Uw klantdossier wordt voor u klaar gezet.",
    consumer: "Consument",
    business: "Zakelijk",
    producer: "Producent",
    error: "Er is iets fout gegaan.",
    loggingin: "Inloggen",
    forgotcredentialstitle: "Inloggegevens vergeten? Geen probleem!",
    forgotcredentialssuccessmessage:
        "Indien de gebruikersnaam bij ons bekend is, dan is er zojuist een e-mail verzonden naar het e-mailadres dat bij ons bekend is. In deze mail vindt u een link waarmee u uw wachtwoord opnieuw kunt instellen. Let op: het kan soms zijn dat deze mail in uw spambox terecht komt.",
    forgotcredentialserrormessage:
        "Er is iets misgegaan bij het aanvragen van een wachtwoordherstel.",
    forgotcredentials: "Inloggegevens vergeten?",
    forgotusername: "Gebruikersnaam vergeten",
    forgottext:
        "Vul je gebruikersnaam hieronder in en we sturen je een e-mail om je wachtwoord te herstellen. Als je onlangs bent overgestapt naar onze nieuwe omgeving van Mijn Greenchoice Zakelijk, gebruik dan het e-mailadres dat bij ons bekend is.",
    setnewpassword: "Nieuw wachtwoord instellen",
    newpassword: "Nieuw wachtwoord",
    newpasswordhelpmessage:
        "Je wachtwoord moet minimaal 8 karakters zijn, waaronder minimaal 1 hoofdletter. 1 cijfer en 1 leesteken.",
    newpasswordplaceholder: "Vul hier je gewenste wachtwoord in",
    newpassworderrormessage: "Je wachtwoord voldoet niet.",
    repeatpassword: "Herhaal nieuw wachtwoord",
    repeatpasswordplaceholder: "Vul hier opnieuw je gewenste wachtwoord in",
    repeatpassworderrormessage:
        "De wachtwoorden komen niet met elkaar overeen.",
    confirmnewpassword: "Bevestig wachtwoord",
    resetsuccessmessage:
        "Je nieuwe wachtwoord instellen is gelukt. Je wordt doorgestuurd naar de inlogpagina.",
    resetwarningmessage:
        "De reset link die je hebt gebruikt is ongeldig of verlopen.",
    reseterrormessage:
        "Er is iets misgegaan bij het aanpassen van je wachtwoord.",
};
