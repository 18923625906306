<template>
    <li
        ref="menu"
        class="mt-4 md:mt-0"
        :class="{ relative: hasChildren }"
        :role="props.split && hasChildren ? 'group' : ''"
    >
        <router-link
            :id="'nav-item-' + props.route.name"
            :class="{
                'px-4': !props.split,
                'pl-4 pr-1': props.split,
                'text-gray-900 hover:text-green-500 dark:text-gray-200':
                    !isActive,
                'text-green-500': isActive,
                linkClasses,
            }"
            :to="props.route"
            aria-current="page"
            class="inline-block py-2 font-source-sans dark:hover:text-white"
        >
            <slot />
        </router-link>
        <button
            v-if="props.split && hasChildren"
            class="border-gray-200 py-2 pr-1 text-sm font-medium text-gray-900 hover:text-green-500 dark:text-gray-200 dark:hover:text-white md:hidden"
            type="button"
            @click="dropdown = !dropdown"
        >
            <font-awesome-icon class="icon" icon="angle-down" />
        </button>
        <gz-nav-dropdown
            v-if="children.length"
            :nav-key="props.navigationType"
            :routes="props.route.children"
            :visible="dropdown"
            left
            @close-dropdown="closeDropdown"
        />
    </li>
</template>

<script lang="ts" setup>
import { computed, ref, Ref } from "vue";
import GzNavDropdown from "./GzNavDropdown.vue";
import { type RouteRecordNormalized, useRoute } from "vue-router";

/**
 * Define props with type annotations
 */
const props = defineProps({
    route: {
        type: Object,
        required: true,
    },
    split: Boolean,
    navigationType: {
        type: String,
        default: "dropdown",
    },
});

const dropdown: Ref<boolean> = ref(false);
const $route = useRoute();

/**
 * Returns routes children list if the route has children.
 * @returns {Array}
 */
const children = computed(() => {
    if (props.route.children) {
        return props.route.children.filter(
            (route: RouteRecordNormalized) =>
                route.meta && route.meta.navigation === props.navigationType,
        );
    }

    return [];
});

const isActive = computed(() => {
    return $route.name === props.route.name;
});

const linkClasses = computed(() => {
    return isActive.value
        ? "text-green-500"
        : "text-gray-900 dark:text-gray-200 hover:text-green-500";
});

function closeDropdown() {
    dropdown.value = false;
}

/**
 * Returns whether the route has children in navigation.
 * @returns {boolean}
 */
const hasChildren = computed(() => children.value.length > 0);
</script>
