import type { FixedTariff, VariableTariff } from "@/@types/tariff";
import type { Segment } from "@/@types/segment";
import moment from "moment/moment";

export class Contract {
    aantal_segmenten: number = 0;
    actueel: boolean = false;
    begindatum?: string;
    einddatum?: string;
    id: number = 0;
    nr: number = 0;
    omschrijving: string = "";
    product: "CMB" | "ELK" | "GAS" = "CMB";
    propositie_id: number = 0;
    segmenten: Segment[] = [];
    status = "Actueel" as const;
    tarieven: {
        vast: FixedTariff[];
        variabel: VariableTariff[];
    } = {
        vast: [],
        variabel: [],
    };
    tekendatum?: string;

    /**
     * Checks if the variable is valid.
     *
     * @return {boolean} True if the variable is valid, otherwise false.
     */
    isVariable(): boolean {
        if (this.einddatum !== null) {
            return moment(this.einddatum, "YYYY-MM-DD").year() > 9000;
        }

        return false;
    }
}
