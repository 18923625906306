import { Directive, DirectiveBinding } from "vue";

type MaskSymbol = "#" | "X" | "S" | "A" | "a" | "!";

type MaskToken = {
    pattern: RegExp;
     
    transform?: (character: string) => string;
};
type MaskTokens = Record<MaskSymbol, MaskToken | undefined>;

const maskTokenDefinitions: MaskTokens = {
    "#": { pattern: /\d/ },
    X: { pattern: /[0-9a-zA-Z]/ },
    S: { pattern: /[a-zA-Z]/ },
    A: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
    a: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
    "!": { pattern: /.*/ }, // TODO werkt nog niet naar behoren
};

let previousMaskedValue: string = "";

/**
 * Handles the application of a mask on an HTMLInputElement and triggers an event.
 *
 * @param {HTMLInputElement} input - The input element to attach the mask events to.
 * @param {string} mask - The mask to apply to the input value.
 * @return {void}
 */
function handleMaskApplyAndEvent(input: HTMLInputElement, mask: string): void {
    const currentInput = unmask(input.value, mask);

    handleTransformAndMasking(input, mask, currentInput);
}

/**
 * Handles transforming and masking the input value.
 *
 * @param {HTMLInputElement} input - The input element to handle.
 * @param {string} mask - The mask to apply to the input value.
 * @param {string} unmaskedInputValue - The unmasked input value.
 *
 * @return {void}
 */
function handleTransformAndMasking(
    input: HTMLInputElement,
    mask: string,
    unmaskedInputValue: string,
): void {
    previousMaskedValue = applyMask(unmaskedInputValue, mask);
    if (input.value !== previousMaskedValue) {
        input.value = previousMaskedValue;
        dispatchInputEvent(input);
    }
}

/**
 * Dispatches an input event to the specified input element.
 *
 * @param {HTMLInputElement} input - The input element to dispatch the event to.
 *
 * @return {void}
 */
function dispatchInputEvent(input: HTMLInputElement): void {
    const event = new Event("input", { bubbles: true });
    input.dispatchEvent(event);
}

/**
 *
 */
function applyMask(value: string, mask: string): string {
    let result = "";
    let valuePointer = 0;
    let maskPointer = 0;
    while (valuePointer < value.length) {
        if (maskPointer >= mask.length) {
            break;
        }
        const maskChar = mask[maskPointer];
        const token = maskTokenDefinitions[maskChar!];
        if (token) {
            if (token.pattern.test(value[valuePointer])) {
                const transformedChar = token.transform
                    ? token.transform(value[valuePointer])
                    : value[valuePointer];
                result += transformedChar;
                valuePointer++;
            }
            maskPointer++;
        } else {
            result += maskChar;
            maskPointer++;
        }
    }
    return result;
}

/**
 * Unmasks a given value by removing characters based on a given mask.
 *
 * @param {string} value - The value to be unmasked.
 * @param {string} mask - The mask used to determine which characters to remove.
 * @returns {string} - The unmasked value.
 */
function unmask(value: string, mask: string): string {
    let unmaskedValue = "";
    let maskPointer = 0;
    for (let i = 0; i < value.length; i++) {
        const maskChar = mask[maskPointer];
        const token = maskTokenDefinitions[maskChar!];
        if (token) {
            if (token.pattern.test(value[i])) {
                const transformedChar = token.transform
                    ? token.transform(value[i])
                    : value[i];
                unmaskedValue += transformedChar;
            }
            maskPointer++;
        } else if (value[i] !== maskChar) {
            unmaskedValue += value[i];
        } else {
            maskPointer++;
        }
    }
    return unmaskedValue;
}

const MaskDirective: Directive = {
    updated(el: HTMLElement, binding: DirectiveBinding): void {
        const input: HTMLInputElement =
            el instanceof HTMLInputElement ? el : el.querySelector("input")!;

        if (binding.value) {
            handleMaskApplyAndEvent(input, binding.value as string);
        }
    },
};

export default MaskDirective;
