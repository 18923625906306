import { Ref } from "vue";
import { acceptHMRUpdate, defineStore } from "pinia";
import { producerCustomer } from "@/@types/producerCustomer";
import { localStorageRef } from "@/utilities/localStorage";

export const useProducerStore = defineStore("producer", () => {
    const customer: Ref<producerCustomer | null> = localStorageRef(
        null,
        "producer.customer",
    );

    const numberOfCustomers: Ref<number | null> = localStorageRef(
        null,
        "producer.numberOfCustomers",
    );

    function setCustomer(value: producerCustomer | null): void {
        customer.value = value;
    }

    function setNumberOfCustomers(value: number | null): void {
        numberOfCustomers.value = value;
    }

    function $reset(): void {
        setCustomer(null);
        setNumberOfCustomers(null);
    }

    return {
        $reset,
        customer,
        numberOfCustomers,
        setCustomer,
        setNumberOfCustomers,
    };
});

// https://pinia.vuejs.org/cookbook/hot-module-replacement.html#HMR-Hot-Module-Replacement-
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useProducerStore, import.meta.hot));
}
