import { defineStore, acceptHMRUpdate } from "pinia";
import { ref, Ref } from "vue";

export type ServicePartnerCustomer = {
    klantnummer: number;
    klantnaam: string;
};

export const useServicePartnerStore = defineStore("servicepartner", () => {
    const availableClients: Ref<ServicePartnerCustomer[]> = ref(
        JSON.parse(localStorage.getItem("availableCustomers")!) ?? [],
    );

    const selectedClient: Ref<ServicePartnerCustomer | null> = ref(
        JSON.parse(localStorage.getItem("selectedCustomer")!) ?? null,
    );
    const isPartner: Ref<boolean> = ref(
        JSON.parse(localStorage.getItem("isPartner")!) ?? false,
    );

    const setIsPartner = (val: boolean) => {
        isPartner.value = val;
        localStorage.setItem("isPartner", JSON.stringify(val));
    };

    const setAvailableClients = (customers: ServicePartnerCustomer[]) => {
        availableClients.value = customers;
        localStorage.setItem("availableCustomers", JSON.stringify(customers));
    };

    const setSelectedClient = (customer: ServicePartnerCustomer) => {
        selectedClient.value = customer;
        localStorage.setItem("selectedCustomer", JSON.stringify(customer));
    };

    function $reset(): void {
        availableClients.value = [];
        selectedClient.value = null;
        isPartner.value = false;
    }

    return {
        availableClients,
        selectedClient,
        isPartner,
        setIsPartner,
        setAvailableClients,
        setSelectedClient,
        $reset,
    };
});

// https://pinia.vuejs.org/cookbook/hot-module-replacement.html#HMR-Hot-Module-Replacement-
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useServicePartnerStore, import.meta.hot))
}
