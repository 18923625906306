import navigatie from "./navigatie";
import meterstanden from "./meterstanden";
import mutatie from "./mutatie";
import inloggen from "./inloggen";
import aansluitingen from "./aansluitingen";
import invoices from "./invoices";
import verbruik from "./verbruik";
import segmenten from "./segmenten";
import customerselection from "./customerselection";
import installmentAmount from "./installmentAmount";
import dashboard from "./dashboard";
import footer from "./footer";

export default {
    nl: {
        navigatie: navigatie,
        meterstanden: meterstanden,
        mutatie: mutatie,
        login: inloggen,
        verbruik: verbruik,
        customerselection: customerselection,
        installmentAmount: installmentAmount,
        dashboard: dashboard,
        accountinfo: {
            address: "Adres",
            back: "Terug",
            cellphone: "Mobiel",
            contractholder: "Contracthouder",
            customerid: "Klantnummer",
            email: "E-mail",
            homephone: "Telefoon",
            name: "Naam",
            password: "Wachtwoord",
            residence_function: "Verblijfsfunctie",
            thanks: "Dank voor uw bericht",
            username: "Gebruikersnaam",
            usernamehelpmessage:
                "Bijvoorbeeld je e-mailadres of je Greenchoice klantnummer",
            usernameplaceholder: "Vul hier je gebruikersnaam in",
        },
        segments: segmenten,
        ...aansluitingen,
        contact: {
            adres: "Adres",
            back: "Terug",
            bic: "BIC",
            btwnr: "BTW nr",
            choosecontact: "Kies contactpersoon",
            contractgegevens: "Contractgegevens",
            description: "",
            email: "E-mail",
            handelsregisternr: "KvK nr",
            iban: "IBAN",
            info: "Contactgegevens",
            internet: "Internet",
            message: "Bericht",
            meterstanden: "Meterstanden",
            name: "Naam",
            nieuwsbrief: "Nieuwsbrief",
            onderwerp: "Onderwerp",
            phonenumber: "Telefoonnummer",
            postadres: "Postadres",
            send: "Verzend bericht",
            sendmessage: "Verzend bericht",
            telefoon: "Telefoon",
            termijnfactuur: "Termijnfactuur",
            thanks: "Bedankt dat u contact met ons opneemt. Één van onze collega's neemt snel contact met u op.",
            verzamelfactuur: "Jaar- en verzamelfacturen",
            mkb: "We zijn bereikbaar van maandag t/m vrijdag van 09:00 tot 12:00 uur op 010 - 850 06 61.",
            grootzakelijk:
                "We zijn bereikbaar van maandag t/m vrijdag van 09:00 tot 12:00 uur op 010 - 309 09 77.",
        },
        contacts: {
            add: "Toevoegen contactpersoon",
            addcontact: "Hier kunt u een contactpersoon toevoegen of wijzigen",
            addition: "Toevoeging",
            address: "Adres",
            adres: "Adres",
            afdeling: "Afdeling",
            bic: "BIC",
            btwnr: "BTW nr",
            cancel: "Annuleer",
            cellphone: "Mobiel",
            city: "Plaats",
            contactaanpassen: "Opslaan",
            contacttoevoegen: "Toevoegen",
            default: "Contactpersoon is als standaard ingesteld",
            department: "Afdeling",
            description:
                'Hieronder kunt u de gegevens vinden van alle contactpersonen die bij ons bekend zijn. Wilt u gegevens wijzigen? Dan kunt u dit regelen door middel van de knop "Pas contactpersoon aan".',
            edit: "Pas contactpersoon aan",
            email: "E-mail",
            geslacht: "Geslacht",
            handelsregisternr: "KvK nr",
            huisnummer: "Huisnummer",
            iban: "IBAN",
            info: "Contactgegevens",
            initials: "Initialen",
            internet: "Internet",
            mobiel: "Mobiel",
            name: "Naam",
            number: "Huisnummer",
            phone: "Telefoon",
            postadres: "Postadres",
            postcode: "Postcode",
            prefix: "Tussenvoegsel",
            sendmessage: "Verzend bericht",
            straat: "Straat",
            street: "Straat",
            surname: "Achternaam",
            telefoon: "Telefoon",
            toevoeging: "Toevoeging",
            woonplaats: "Woonplaats",
            zipcode: "Postcode",
        },
        contract: {
            contractDetails: "Contractgegevens",
            name: "Contractnaam",
            begindate: "Begindatum",
            enddate: "Einddatum",
            noenddate: "Contract voor onbepaalde tijd",
            signdate: "Tekendatum",
            segments: "Segmenten",
            tariffs: "Tarieven",
            variabletariffs: "Leveringskosten",
            fixedtariffs: "Vaste tarieven",
            governmenttaxes: "Overheidsheffingen",
            taxincluded: "Inclusief BTW",
            taxexcluded: "Exclusief BTW",
            taxdecrease:
                "Let op: Tussen 1 juli 2022 en 1 januari 2023 is het BTW-tarief verlaagd naar 9%.",
            taxdiscount:
                "Hebt u een kleinverbruikaansluiting met een maximale grootte van 3x80 Ampère, dan ontvangt u in november én december 2022 een korting op uw termijnnota. Dit is een vaste korting van € 190 per maand.",
            retrieving: "{msg} worden opgehaald",
        },
        contracts: {
            aantal_aansluitingen: "Aantal aansluitingen",
            aantal_actueel: "Actuele aansluitingen",
            aantal_beeindigd: "Be\u00ebindigde aansluitingen",
            aantal_segmenten: "Aantal segmenten",
            afgesproken_termijnbedrag: "Termijnbedrag",
            contract: "Contractnaam",
            contract_status: "Contractstatus",
            contractname: "Contractnaam",
            deliverycosts: "Tarieven levering",
            description:
                "Wilt u zien wat de tarieven, vaste kosten en overheidsheffingen zijn van een actuele of be\u00ebindigde leveringsovereenkomst? Selecteer dan het gewenste contract om meer informatie te tonen.",
            editinstallmentamount: "Pas termijnbedrag aan voor {segment}",
            cantchangeinstallmentamount:
                "Het termijnbedrag voor {segment} kan niet aangepast worden. Een termijnbedrag kan niet negatief zijn.",
            changedinstallmentamount:
                "Het termijnbedrag is aangepast voor {segment}",
            factuurgroep: "Factuurgroep",
            fixedcosts: "Vaste kosten",
            governmenttaxes: "Overheidsheffingen",
            naam: "Segmentnaam",
            showconnectionsonmap:
                "Geef huidige zichtbare aansluitingen weer op de kaart",
            status: "Contractstatus",
            termijnbedrag: "Termijnbedrag",
            variablecosts: "Variabele tarieven",
        },
        data: { description: "Graag aanvullen door Marketing" },
        faq: {
            allcategories: "Alle categorie\u00ebn",
            currentcategories: "Huidige categorie\u00ebn",
            noitemsfound: "Geen resultaten gevonden",
            searchin: "Zoeken in",
        },
        finance: {
            calculatedinstallmentamount: "Berekend termijnbedrag",
            currentinstallmentamount: "Huidig termijnbedrag",
            description:
                "Dit is een overzicht van alle facturen. Wilt u meer details zien dan kunt u klikken op het 'oogje' of het PDF-bestand downloaden voor meer informatie. Met de zoek- en filterfunctie kunt u eenvoudig de gewenste facturen er bij halen. Eventueel rood gemarkeerde facturen zijn nog niet voldaan.",
            editinstallmentamount: "Pas termijnbedrag aan",
            installmentamountinput: "Nieuw termijnbedrag",
            installmentamountperiod: "Per",
            invoicegroups: "Factuurgroepen",
            loadinginstallmentamounts: "Uw gegevens worden opgehaald.",
            resetinstallmentamount: "Herstel",
        },
        footer: footer,
        general: {
            address: "Adres",
            addressinformation: "Adresgegevens",
            agree: "Akkoord",
            allconnections: "Alle aansluitingen",
            allsegments: "Alle segmenten",
            back: "Terug",
            cancel: "Annuleren",
            comments: "Opmerkingen",
            confirm: "Bevestigen",
            confirmation: "Bevestiging",
            connection: "Aansluiting",
            connections: "Aansluitingen",
            contact: "Contactpersoon",
            counter: "Levering",
            currentpassword: "Huidige wachtwoord",
            day: "Dag",
            end: "Eindstand",
            endingdate: "Einddatum",
            everything: "Alles",
            factuur: "Factuur {factuur_id} is nu in te zien.",
            filteron: "Filteren op {type}",
            frequentlyaskedquestions: "Veelgestelde vragen",
            hour: "Uur",
            housenumber: "Huisnummer",
            housenumberaddition: "Toevoeging",
            klantnummer: "Inlognaam/ e-mailadres",
            loading: "Gegevens ophalen. Moment geduld.",
            loadingclientinformation: "Uw gegevens worden opgehaald",
            login: "Inloggen",
            loginerror:
                'Er is iets misgegaan bij het inloggen, controleer uw gebruikersnaam en wachtwoord of klik <a href="https://www.greenchoice.nl/klantenservice/contactopties-zakelijk/" target="_blank">hier</a> om contact op te nemen met onze klantenservice.',
            logout: "Uitloggen",
            maand: "Maand | Maanden",
            markallasread: "Markeer alles als gelezen",
            meterreading: "Meterstand",
            month: "Maand",
            new: "Nieuw | Nieuwe",
            newpassword: "Nieuw wachtwoord",
            no: "Nee",
            place: "Plaats",
            postalcode: "Postcode",
            quarter: "Kwartier",
            quickto: "Snel naar",
            requiredfield: "Dit veld is verplicht.",
            retrieving: "{msg} worden opgehaald",
            save: "Opslaan",
            search: "Zoek | Zoeken",
            segments: "Segment",
            send: "Verzenden",
            selectoption: "- Selecteer een optie -",
            smartmeter:
                "Dit betreft een slimme meter. Wij zullen de meterstanden uitlezen bij de netbeheerder. Voor deze aansluiting hoeft u dus geen meterstanden door te geven",
            start: "Beginstand",
            startdate: "Begindatum",
            startingdate: "Ingangsdatum",
            street: "Straatnaam",
            surname: "Achternaam",
            unit_ELK: "kWh",
            unit_GAS: "m\u00b3",
            usage: "Verbruik",
            username: "Inlognaam",
            validatepassword: "Bevestig wachtwoord",
            wachtwoord: "Wachtwoord",
            wachtwoordplaceholder: "Vul hier je wachtwoord in",
            year: "Jaar",
            yes: "Ja",
            your: "u",
            yours: "uw",
            maintenance:
                "Op 16 juni van 12:00 tot 17:00 uur vindt er gepland onderhoud plaats aan onze systemen. Hierdoor is Mijn Greenchoice Zakelijk gedurende deze tijd niet toegankelijk. Het onderhoud is bewust gepland op een tijdstip waarop er zo min mogelijk gebruikers actief zijn. Onze excuses voor eventuele overlast.",
        },
        invoices: invoices,
        partner: {
            selectclient: "Selecteer een klant",
        },
        profile: {
            security: "Beveiliging",
            change: "Wijzigen",
            password: "Wachtwoord",
            passwordresetsuccessmessage:
                "Je krijgt een e-mail met een link om je wachtwoord aan te passen.",
            passwordreseterrormessage:
                "Er is iets misgegaan bij het aanvragen van een wachtwoordreset.",
            stars: "**************",
        },
        relocation: {
            filterean: "Filter op aansluiting",
            filtersegment: "Filter op segmentnaam",
            itemsperpage: "Aantal per pagina",
            selectperpage: "Aantal per pagina",
        },
        segment: {
            contract_status: "Contractstatus",
            financegroup: "Factuurgroep",
            installmentamount: "Termijnbedrag",
            name: "Segmentnaam",
            number_active: "Actieve aansluitingen",
            number_connections: "Aantal aansluitingen",
            number_ended: "Be\u00ebindigde aansluitingen",
        },
        usage: {
            aansluiting: "Aansluiting",
            chart_title: "Verbruik",
            title: "Verbruik voor {aansluitingen} | Verbruik in {datum} voor {aansluitingen}",
            datafound: "Tussen {van} en {tot} is er verbruik beschikbaar",
            dateoutsiderange:
                "Geen data beschikbaar in de gekozen verbruiksperiode",
            dateperiod: "Verbruiksperiode",
            description:
                'U ziet hieronder alleen een gedetailleerd verbruik van uw slimme meter of telemetrische aansluiting, als de uitleesfrequentie van uw meter daarvoor is ingesteld. U kunt deze uitleesfrequentie wijzigen met behulp van de knop "Uitlezen slimme meter".',
            exportdatato: "Exporteer naar {formaat}",
            exportdata: "Download verbruiksdata (CSV)",
            exportingdata: "Downloaden...",
            compare: "Vergelijk verbruik aansluitingen",
            comparing: "1 aansluiting | {aantal} aansluitingen",
            loadingchart: "Verbruik voor geselecteerde aansluitingen ophalen",
            fillinpostalcodeorean: "Selecteer aansluiting",
            itemsperpage: "Aantal per pagina",
            kiesaansluiting: "Selecteer aansluiting",
            nometerauthorization:
                "Voor deze aansluiting is geen SEM-machtiging afgegeven. U kunt niet verder inzoomen dan op maandniveau.",
            nometerreadingsfound: "Geen meterstanden gevonden",
            numberconnections: "{aantal} van {totaal} aansluitingen",
            resetfilters: "Filter wissen",
            selectday: "Dag",
            selecthour: "Uur",
            selectmonth: "Maand",
            selectyear: "Jaar",
            selecteans: "Selecteer één of meerdere aansluitingen",
            togglefullscreen: "Vergroot / verklein het scherm",
            zoekaansluiting: "Zoek naar aansluiting",
            zoomin: "Inzoomen",
            zoomlevel: "Detailweergave",
            zoomout: "Zoom de grafiek uit",
            total: "Laat totalen in de grafiek zien",
            percentage: "Laat percentages in de kolommen zien",
            groupBy: "Groepeer op {group}",
            selectedperiod: "Verbruik in {datum}",
            startdate: "Startdatum verbruiksdata: ",
        },
        meterreadings: {
            description:
                "Wilt u meterstanden inzien? Onder het tabblad 'Meterstanden' kunt u de historie van meterstanden zien voor een specifieke aansluiting. Wilt u meterstanden doorgeven voor de jaarnota, start levering of einde levering? Dan kunt u onder 'Meterstanden doorgeven' een overzicht zien van de aansluitingen waarvoor u een meterstand kunt indienen. Voer de stand(en) in en druk op verzenden om ze in te dienen. De meterstanden krijgen de datum mee van 'vandaag' bij het verzenden.",
            meterreadings_succesfull_saved: "De meterstanden zijn verzonden",
            meterreadingssaved: "Meterstanden zijn verzonden",
            nocountersmeter:
                "Technische fout, er kunnen geen meterstanden worden doorgegeven",
            offpeakmeter: "Meterstand Laag",
            peakmeter: "Meterstand Normaal",
            previousrecord: "Vorige stand",
            previousrecord_ll: "Vorige stand laag",
            previousrecord_ln: "Vorige stand normaal",
            previousrecord_tn: "Vorige stand teruglevering normaal",
            previousrecord_tl: "Vorige stand teruglevering laag",
            savemeterreadings: "Meterstanden verzenden",
            singlemeter: "Meterstand Normaal",
            meterreadings: "Meterstanden",
            extra_info: "Extra informatie",
            submit_meterreadings: "Meterstanden invullen",
            nodata: "Geen meterstanden gevonden",
        },
    },
};
