<template>
    <span
        class="mr-2 rounded bg-green-800 text-xs font-semibold text-white dark:bg-green-500 dark:text-white"
        :class="classes"
    >
        <slot />
    </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
    small: {
        required: false,
        type: Boolean,
        default: false,
    },
});

const classes = computed(() => (props.small ? "px-1 py-0.5" : "px-2.5 py-0.5"));
</script>
