export default {
    contract_status: "Contractstatus",
    financegroup: "Factuurgroep",
    installmentamount: "Termijnbedrag",
    name: "Segmentnaam",
    nodata: "Geen segmenten gevonden",
    number_active: "Actieve aansluitingen",
    number_connections: "Aantal aansluitingen",
    number_ended: "Be\u00ebindigde aansluitingen",
};
