import moment, { Moment } from "moment";

export const dateOverviewFormat = "DD\u00A0MMM\u00A0YYYY";

/**
 * Gets the earliest date from array with date strings
 * @param dates
 */
export function getEarliestDateFromDates(dates: string[]): Moment {
    const moments: Moment[] = dates
        .filter((date) => date) // Check if date has value
        .map((date) => moment(date));

    return moment.min(moments);
}