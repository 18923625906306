import { Ref, computed } from "vue";
import { acceptHMRUpdate, defineStore } from "pinia";
import { LoginType } from "@/composables/user";
import { localStorageRef } from "@/utilities/localStorage";

export const useUserStore = defineStore("user", () => {
    // We are using the localStorageRef function to store the user type, token and tokenExpiresAt to prevent the user from logging out when reopening the site.
    const type: Ref<LoginType> = localStorageRef(
        LoginType.Business,
        "user.type",
    );

    const name: Ref<string> = localStorageRef("", "user.name");
    const token: Ref<string> = localStorageRef("", "user.token");
    const tokenExpiresAt: Ref<string> = localStorageRef(
        "",
        "user.tokenExpiresAt",
    );

    const isBusiness = computed(
        (): boolean => type.value === LoginType.Business,
    );
    const isProducer = computed(
        (): boolean => type.value === LoginType.Producer,
    );

    const isAuthenticatedBusiness: Ref<boolean> = localStorageRef(
        false,
        "user.isAuthenticatedBusiness",
    );

    const isAuthenticatedProducer: Ref<boolean> = localStorageRef(
        false,
        "user.isAuthenticatedProducer",
    );

    const isTokenValid = computed(
        (): boolean =>
            tokenExpiresAt.value !== undefined &&
            Date.now() < parseFloat(tokenExpiresAt.value),
    );

    function setIsAuthenticatedProducer(val: boolean) {
        isAuthenticatedProducer.value = val;
    }

    function setIsAuthenticatedBusiness(val: boolean) {
        isAuthenticatedBusiness.value = val;
    }

    function setToken(val: string) {
        const hoursInFuture = 2;
        const tokenExpiresAtTimestamp = new Date(
            Date.now() + hoursInFuture * 60 * 60 * 1000,
        ).getTime();

        tokenExpiresAt.value = tokenExpiresAtTimestamp.toString();
        token.value = val;
    }

    function setName(val: string) {
        name.value = val;
    }

    function setType(val: LoginType) {
        type.value = val;
    }

    function $reset(): void {
        setIsAuthenticatedProducer(false);
        setIsAuthenticatedBusiness(false);
        type.value = LoginType.Business;
        token.value = "";
        tokenExpiresAt.value = "";
    }

    return {
        $reset,
        isAuthenticatedBusiness,
        isAuthenticatedProducer,
        isBusiness,
        isProducer,
        isTokenValid,
        name,
        token,
        tokenExpiresAt,
        type,
        setIsAuthenticatedProducer,
        setIsAuthenticatedBusiness,
        setName,
        setType,
        setToken,
    };
});

// https://pinia.vuejs.org/cookbook/hot-module-replacement.html#HMR-Hot-Module-Replacement-
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
